<script>
    import { showNavigation } from 'stores.js'
    import classNames from 'classnames'
    import Button from 'components/Button'
    import Link from 'components/Link'
    import Pill from 'components/Pill'

    let className
    export let items = []

    export { className as class }

    const setNav = value => {
        showNavigation.set(value)
        if (value) {
            document.body.classList.add('s-no-vertical-overflow')
        } else {
            document.body.classList.remove('s-no-vertical-overflow')
        }
    }
</script>

<style global>
    @-webkit-keyframes -global-swipeIn {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0%);
        }
    }
    @keyframes -global-swipeIn {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0%);
        }
    }
    :global(.Navigation-list),
    :global(.Navigation-item) {
        padding: 0;
        margin: 0;
        list-style: none;
        font-weight: var(--font-weight-normal);
    }
    :global(.Navigation-list) {
        display: flex;
        align-items: center;
    }
    :global(.Navigation-link) {
        padding: 0.5em 1em;
    }
    :global(.Navigation) :global(.Navigation-toggleButton) {
        display: none;
        opacity: 1;
        padding: 0;
    }
    :global(.Navigation) :global(.Navigation-toggleButton) :global(.Button-icon) :global(.Icon-wrapper) {
        width: 30px;
        height: 30px;
    }
    :global(.Navigation-item--selected) {
        font-weight: var(--font-weight-bold);
    }
    :global(.Navigation-item--selected) :global(.Navigation-link) {
        font-weight: var(--font-weight-bold);
        opacity: 1;
    }
    :global(.Navigation-link) {
        opacity: 0.5;
        transition: var(--transition);
        position: relative;
    }
    :global(.Navigation-link[data-text]::before) {
        content: attr(data-text);
        font-weight: var(--font-weight-bold);
        visibility: hidden;
    }
    :global(.Navigation-link[data-text]) :global(.Link-text) {
        position: absolute;
        top: 0.5em;
        left: 0;
        right: 0;
        text-align: center;
    }
    :global(.Navigation-link:hover) {
        opacity: 1;
    }
    :global(.Navigation-pill) {
        margin: -0.25em 1rem 0 -1rem;
        position: relative;
        top: -0.25em;
    }
    @media (max-width: 767px) {
        :global(.s-no-vertical-overflow) {
            overflow-y: hidden;
        }

        :global(.Navigation) :global(.Navigation-toggleButton) {
            display: block;
            border-radius: unset;
        }
        :global(.Navigation-closeButton) {
            position: absolute;
            top: 1em;
            right: var(--layout-gutter);
        }
        :global(.Navigation-wrapper) {
            transform: translateX(-100%);
            position: fixed;
            top: 150px;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: auto;
            opacity: 0;
            padding: 0 var(--layout-side-gutter);
            font-size: var(--text-size-large);
            z-index: -1;
            perspective: 2000px;
            pointer-events: none;
            background-color: var(--color-white);
        }
        :global(.Navigation) :global(.Navigation-link) {
            padding: 0.5em 1em 0.5em 0;
            opacity: 1;
        }

        :global(.Navigation) :global(.Navigation-link) :global(.Link-text) {
            text-align: left;
        }

        :global(.Navigation) :global(.Navigation-link) :global(.Link-text),
        :global(.Navigation-link[data-text]::before) {
            color: var(--color-link);
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            font-size: 1.65rem;
        }

        :global(.Navigation) :global(.Navigation-pill) {
            vertical-align: text-top;
        }

        :global(.Navigation--show) :global(.Navigation-wrapper) {
            display: block;
            transform: translateX(0);
            opacity: 1;
            z-index: 1;
            pointer-events: auto;
            transition: 0.35s ease-in;
        }
        :global(.Navigation-list) {
            font-size: 1.5em;
            position: absolute;
            transform: rotateY(-90deg);
            margin: 0;
            flex-direction: column;
            align-items: flex-start;
            transition: none;
            transform-origin: 0 0;
            text-shadow: 0 0 1px var(--color-white);
        }
        :global(.Navigation--show) :global(.Navigation-list),
        :global(.Navigation-list[focus-within]) {
            transform: rotateY(0deg);
        }
        :global(.Navigation--show) :global(.Navigation-list),
        :global(.Navigation-list:focus-within) {
            transform: rotateY(0deg);
        }
    }</style>

<nav
    class={classNames('Navigation', className, {
        'Navigation--show': $showNavigation
    })}>
    {#if $showNavigation}
        <Button
            plain
            class="Navigation-toggleButton"
            on:click={() => {
                setNav(false)
            }}
            icon={{ type: 'close', size: 'big' }}
            text={'Toggle menu'}
            hiddenText
            rounded />
    {:else}
        <Button
            plain
            class="Navigation-toggleButton"
            on:click={() => {
                setNav(true)
            }}
            icon={{ type: 'menu', size: 'big' }}
            text={'Toggle menu'}
            hiddenText
            rounded />
    {/if}

    <div class="Navigation-wrapper">
        {#if items.length}
            <ul class="Navigation-list">
                {#each items as { pill, ...item }, index}
                    <li
                        class={classNames('Navigation-item', {
                            'Navigation-item--selected': item.selected
                        })}>
                        <Link
                            plain
                            rel="prefetch"
                            {...item}
                            attributes={{ 'data-text': item.text }}
                            class={classNames('Navigation-link', {
                                'Navigation-link--selected': item.selected
                            })}
                            on:click={() => {
                                setNav(false)
                            }} />
                        {#if pill}
                            <Pill
                                size="small"
                                class="Navigation-pill"
                                text={pill} />
                        {/if}
                    </li>
                {/each}
            </ul>
        {/if}
    </div>
</nav>
