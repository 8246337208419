<script context="module">
    export async function preload({ params, query }) {
        const global = await this.fetch('global.json', {
            credentials: 'include'
        }).then(res => res.json())
        return { global }
    }
</script>

<script>
    import Framework from 'components/Framework'
    import { globalStore } from 'stores.js'

    export let segment
    export let global = {}

    globalStore.set(global)
</script>

<style global>:global(:root) {
    --breakpoint-small: 576px;
    --breakpoint-medium: 768px;
    --breakpoint-large: 1024px;
    --breakpoint-xlarge: 1280px;
}
:global(html) {
    font: normal 300 1rem / 1.5 'KommonGrotesk', BlinkMacSystemFont, -apple-system,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font: var(--text-normal);
    font-size: 16px;
    font-size: var(--text-size-base);
    background: rgba(255, 255, 255, 1);
    background: var(--color-background);
    color: #010357;
    color: var(--color-text);
}
:global(body) {
    padding: 0;
    margin: 0;
    /*Added because of horizontal scrollbar 100vw*/
    overflow-x: hidden;
    width: 100vw;
}
:global(*) {
    box-sizing: border-box;
}
:global(a) {
    color: inherit;
    display: inline-block;
}
:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6) {
    margin: 0 0 0 0;
}
:global(h1:first-child),
:global(h2:first-child),
:global(h3:first-child),
:global(h4:first-child),
:global(h5:first-child),
:global(h6:first-child),
:global(p:first-child) {
    margin-top: 0;
}
:global(h1:last-child),
:global(h2:last-child),
:global(h3:last-child),
:global(h4:last-child),
:global(h5:last-child),
:global(h6:last-child),
:global(p:last-child) {
    margin-bottom: 0;
}
:global(img) {
    max-width: 100%;
    width: auto;
    height: auto;
    display: block;
}
:global(figure) {
    margin: 0;
    display: block;
}
:global(:root) {
    --transition: 0.35s ease-out;
    --transition-slow: 0.5s ease-out;
    --transition-easing: ease-out;
}
:global(:root) {
    --border-radius: 12px;
}
:global(:root) {
    --color-light-blue: #e5e6f5;
    --color-blue: #2e2cb1;
    --color-dark-blue: #010357;
    --color-darker-blue: #000251;
    --color-pink: #f0a1de;
    --color-turquoise: #a1fcfe;
    --color-light-grey: #e4e5ed;
    --color-grey: #999;
    --color-white: #fff;
    --color-facebook: #4a66ad;
    --color-link: #020353;

    --color-hover: var(--color-light-blue);

    --color-text: var(--color-dark-blue);
    --color-background: rgba(255, 255, 255, 1);
    --color-background-with-transparency: rgba(255, 255, 255, 0.96);
    --color-dark-background-with-transparency: rgba(0, 0, 0, 0.7);
    --color-primary-action: var(--color-pink);
    --color-secondary-action: var(--color-turquoise);

    --color-blue-main: #010357;
    --color-turquoise-main: #b6fafd;
    --color-pink-main: #f0a1de;
}
:global(:root) :global(.color-primary-action) {
    color: #f0a1de;
    color: var(--color-primary-action);
}
:global(:root) :global(.color-secondary-action) {
    color: #a1fcfe;
    color: var(--color-secondary-action);
}
:global(:root) :global(.color-text) {
    color: #010357;
    color: var(--color-text);
}
:global(:root) {
    --column-max-width: var(--layout-content-width);
}
:global(:root) :global(.columns-1) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12);
        width: calc(var(--column-max-width, 100%) / 12);
    }
:global(:root) :global(.columns-2) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 2);
        width: calc(var(--column-max-width, 100%) / 12 * 2);
    }
:global(:root) :global(.columns-3) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 3);
        width: calc(var(--column-max-width, 100%) / 12 * 3);
    }
:global(:root) :global(.columns-4) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 4);
        width: calc(var(--column-max-width, 100%) / 12 * 4);
    }
:global(:root) :global(.columns-5) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 5);
        width: calc(var(--column-max-width, 100%) / 12 * 5);
    }
:global(:root) :global(.columns-6) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 6);
        width: calc(var(--column-max-width, 100%) / 12 * 6);
    }
:global(:root) :global(.columns-7) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 7);
        width: calc(var(--column-max-width, 100%) / 12 * 7);
    }
:global(:root) :global(.columns-8) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 8);
        width: calc(var(--column-max-width, 100%) / 12 * 8);
    }
:global(:root) :global(.columns-9) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 9);
        width: calc(var(--column-max-width, 100%) / 12 * 9);
    }
:global(:root) :global(.columns-10) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 10);
        width: calc(var(--column-max-width, 100%) / 12 * 10);
    }
:global(:root) :global(.columns-11) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 11);
        width: calc(var(--column-max-width, 100%) / 12 * 11);
    }
:global(:root) :global(.columns-12) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(
        100vw - 5vw * 2
    );
        width: var(--column-max-width, 100%);
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-columns-1) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12);
        width: calc(var(--column-max-width, 100%) / 12);
    }
        :global(:root) :global(.s-columns-2) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 2);
        width: calc(var(--column-max-width, 100%) / 12 * 2);
    }
        :global(:root) :global(.s-columns-3) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 3);
        width: calc(var(--column-max-width, 100%) / 12 * 3);
    }
        :global(:root) :global(.s-columns-4) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 4);
        width: calc(var(--column-max-width, 100%) / 12 * 4);
    }
        :global(:root) :global(.s-columns-5) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 5);
        width: calc(var(--column-max-width, 100%) / 12 * 5);
    }
        :global(:root) :global(.s-columns-6) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 6);
        width: calc(var(--column-max-width, 100%) / 12 * 6);
    }
        :global(:root) :global(.s-columns-7) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 7);
        width: calc(var(--column-max-width, 100%) / 12 * 7);
    }
        :global(:root) :global(.s-columns-8) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 8);
        width: calc(var(--column-max-width, 100%) / 12 * 8);
    }
        :global(:root) :global(.s-columns-9) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 9);
        width: calc(var(--column-max-width, 100%) / 12 * 9);
    }
        :global(:root) :global(.s-columns-10) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 10);
        width: calc(var(--column-max-width, 100%) / 12 * 10);
    }
        :global(:root) :global(.s-columns-11) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 11);
        width: calc(var(--column-max-width, 100%) / 12 * 11);
    }
        :global(:root) :global(.s-columns-12) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(
        100vw - 5vw * 2
    );
        width: var(--column-max-width, 100%);
    }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-columns-1) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12);
        width: calc(var(--column-max-width, 100%) / 12);
    }
        :global(:root) :global(.m-columns-2) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 2);
        width: calc(var(--column-max-width, 100%) / 12 * 2);
    }
        :global(:root) :global(.m-columns-3) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 3);
        width: calc(var(--column-max-width, 100%) / 12 * 3);
    }
        :global(:root) :global(.m-columns-4) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 4);
        width: calc(var(--column-max-width, 100%) / 12 * 4);
    }
        :global(:root) :global(.m-columns-5) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 5);
        width: calc(var(--column-max-width, 100%) / 12 * 5);
    }
        :global(:root) :global(.m-columns-6) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 6);
        width: calc(var(--column-max-width, 100%) / 12 * 6);
    }
        :global(:root) :global(.m-columns-7) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 7);
        width: calc(var(--column-max-width, 100%) / 12 * 7);
    }
        :global(:root) :global(.m-columns-8) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 8);
        width: calc(var(--column-max-width, 100%) / 12 * 8);
    }
        :global(:root) :global(.m-columns-9) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 9);
        width: calc(var(--column-max-width, 100%) / 12 * 9);
    }
        :global(:root) :global(.m-columns-10) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 10);
        width: calc(var(--column-max-width, 100%) / 12 * 10);
    }
        :global(:root) :global(.m-columns-11) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 11);
        width: calc(var(--column-max-width, 100%) / 12 * 11);
    }
        :global(:root) :global(.m-columns-12) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(
        100vw - 5vw * 2
    );
        width: var(--column-max-width, 100%);
    }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-columns-1) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12);
        width: calc(var(--column-max-width, 100%) / 12);
    }
        :global(:root) :global(.l-columns-2) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 2);
        width: calc(var(--column-max-width, 100%) / 12 * 2);
    }
        :global(:root) :global(.l-columns-3) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 3);
        width: calc(var(--column-max-width, 100%) / 12 * 3);
    }
        :global(:root) :global(.l-columns-4) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 4);
        width: calc(var(--column-max-width, 100%) / 12 * 4);
    }
        :global(:root) :global(.l-columns-5) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 5);
        width: calc(var(--column-max-width, 100%) / 12 * 5);
    }
        :global(:root) :global(.l-columns-6) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 6);
        width: calc(var(--column-max-width, 100%) / 12 * 6);
    }
        :global(:root) :global(.l-columns-7) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 7);
        width: calc(var(--column-max-width, 100%) / 12 * 7);
    }
        :global(:root) :global(.l-columns-8) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 8);
        width: calc(var(--column-max-width, 100%) / 12 * 8);
    }
        :global(:root) :global(.l-columns-9) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 9);
        width: calc(var(--column-max-width, 100%) / 12 * 9);
    }
        :global(:root) :global(.l-columns-10) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 10);
        width: calc(var(--column-max-width, 100%) / 12 * 10);
    }
        :global(:root) :global(.l-columns-11) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 11);
        width: calc(var(--column-max-width, 100%) / 12 * 11);
    }
        :global(:root) :global(.l-columns-12) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(
        100vw - 5vw * 2
    );
        width: var(--column-max-width, 100%);
    }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-columns-1) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12);
        width: calc(var(--column-max-width, 100%) / 12);
    }
        :global(:root) :global(.xl-columns-2) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 2);
        width: calc(var(--column-max-width, 100%) / 12 * 2);
    }
        :global(:root) :global(.xl-columns-3) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 3);
        width: calc(var(--column-max-width, 100%) / 12 * 3);
    }
        :global(:root) :global(.xl-columns-4) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 4);
        width: calc(var(--column-max-width, 100%) / 12 * 4);
    }
        :global(:root) :global(.xl-columns-5) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 5);
        width: calc(var(--column-max-width, 100%) / 12 * 5);
    }
        :global(:root) :global(.xl-columns-6) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 6);
        width: calc(var(--column-max-width, 100%) / 12 * 6);
    }
        :global(:root) :global(.xl-columns-7) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 7);
        width: calc(var(--column-max-width, 100%) / 12 * 7);
    }
        :global(:root) :global(.xl-columns-8) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 8);
        width: calc(var(--column-max-width, 100%) / 12 * 8);
    }
        :global(:root) :global(.xl-columns-9) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 9);
        width: calc(var(--column-max-width, 100%) / 12 * 9);
    }
        :global(:root) :global(.xl-columns-10) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 10);
        width: calc(var(--column-max-width, 100%) / 12 * 10);
    }
        :global(:root) :global(.xl-columns-11) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 11);
        width: calc(var(--column-max-width, 100%) / 12 * 11);
    }
        :global(:root) :global(.xl-columns-12) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(
        100vw - 5vw * 2
    );
        width: var(--column-max-width, 100%);
    }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-columns-1) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12);
        width: calc(var(--column-max-width, 100%) / 12);
    }
        :global(:root) :global(.maxs-columns-2) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 2);
        width: calc(var(--column-max-width, 100%) / 12 * 2);
    }
        :global(:root) :global(.maxs-columns-3) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 3);
        width: calc(var(--column-max-width, 100%) / 12 * 3);
    }
        :global(:root) :global(.maxs-columns-4) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 4);
        width: calc(var(--column-max-width, 100%) / 12 * 4);
    }
        :global(:root) :global(.maxs-columns-5) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 5);
        width: calc(var(--column-max-width, 100%) / 12 * 5);
    }
        :global(:root) :global(.maxs-columns-6) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 6);
        width: calc(var(--column-max-width, 100%) / 12 * 6);
    }
        :global(:root) :global(.maxs-columns-7) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 7);
        width: calc(var(--column-max-width, 100%) / 12 * 7);
    }
        :global(:root) :global(.maxs-columns-8) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 8);
        width: calc(var(--column-max-width, 100%) / 12 * 8);
    }
        :global(:root) :global(.maxs-columns-9) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 9);
        width: calc(var(--column-max-width, 100%) / 12 * 9);
    }
        :global(:root) :global(.maxs-columns-10) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 10);
        width: calc(var(--column-max-width, 100%) / 12 * 10);
    }
        :global(:root) :global(.maxs-columns-11) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 11);
        width: calc(var(--column-max-width, 100%) / 12 * 11);
    }
        :global(:root) :global(.maxs-columns-12) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(
        100vw - 5vw * 2
    );
        width: var(--column-max-width, 100%);
    }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-columns-1) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12);
        width: calc(var(--column-max-width, 100%) / 12);
    }
        :global(:root) :global(.maxm-columns-2) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 2);
        width: calc(var(--column-max-width, 100%) / 12 * 2);
    }
        :global(:root) :global(.maxm-columns-3) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 3);
        width: calc(var(--column-max-width, 100%) / 12 * 3);
    }
        :global(:root) :global(.maxm-columns-4) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 4);
        width: calc(var(--column-max-width, 100%) / 12 * 4);
    }
        :global(:root) :global(.maxm-columns-5) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 5);
        width: calc(var(--column-max-width, 100%) / 12 * 5);
    }
        :global(:root) :global(.maxm-columns-6) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 6);
        width: calc(var(--column-max-width, 100%) / 12 * 6);
    }
        :global(:root) :global(.maxm-columns-7) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 7);
        width: calc(var(--column-max-width, 100%) / 12 * 7);
    }
        :global(:root) :global(.maxm-columns-8) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 8);
        width: calc(var(--column-max-width, 100%) / 12 * 8);
    }
        :global(:root) :global(.maxm-columns-9) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 9);
        width: calc(var(--column-max-width, 100%) / 12 * 9);
    }
        :global(:root) :global(.maxm-columns-10) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 10);
        width: calc(var(--column-max-width, 100%) / 12 * 10);
    }
        :global(:root) :global(.maxm-columns-11) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 11);
        width: calc(var(--column-max-width, 100%) / 12 * 11);
    }
        :global(:root) :global(.maxm-columns-12) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(
        100vw - 5vw * 2
    );
        width: var(--column-max-width, 100%);
    }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-columns-1) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12);
        width: calc(var(--column-max-width, 100%) / 12);
    }
        :global(:root) :global(.maxl-columns-2) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 2);
        width: calc(var(--column-max-width, 100%) / 12 * 2);
    }
        :global(:root) :global(.maxl-columns-3) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 3);
        width: calc(var(--column-max-width, 100%) / 12 * 3);
    }
        :global(:root) :global(.maxl-columns-4) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 4);
        width: calc(var(--column-max-width, 100%) / 12 * 4);
    }
        :global(:root) :global(.maxl-columns-5) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 5);
        width: calc(var(--column-max-width, 100%) / 12 * 5);
    }
        :global(:root) :global(.maxl-columns-6) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 6);
        width: calc(var(--column-max-width, 100%) / 12 * 6);
    }
        :global(:root) :global(.maxl-columns-7) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 7);
        width: calc(var(--column-max-width, 100%) / 12 * 7);
    }
        :global(:root) :global(.maxl-columns-8) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 8);
        width: calc(var(--column-max-width, 100%) / 12 * 8);
    }
        :global(:root) :global(.maxl-columns-9) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 9);
        width: calc(var(--column-max-width, 100%) / 12 * 9);
    }
        :global(:root) :global(.maxl-columns-10) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 10);
        width: calc(var(--column-max-width, 100%) / 12 * 10);
    }
        :global(:root) :global(.maxl-columns-11) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 11);
        width: calc(var(--column-max-width, 100%) / 12 * 11);
    }
        :global(:root) :global(.maxl-columns-12) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(
        100vw - 5vw * 2
    );
        width: var(--column-max-width, 100%);
    }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-columns-1) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12);
        width: calc(var(--column-max-width, 100%) / 12);
    }
        :global(:root) :global(.maxxl-columns-2) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 2);
        width: calc(var(--column-max-width, 100%) / 12 * 2);
    }
        :global(:root) :global(.maxxl-columns-3) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 3);
        width: calc(var(--column-max-width, 100%) / 12 * 3);
    }
        :global(:root) :global(.maxxl-columns-4) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 4);
        width: calc(var(--column-max-width, 100%) / 12 * 4);
    }
        :global(:root) :global(.maxxl-columns-5) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 5);
        width: calc(var(--column-max-width, 100%) / 12 * 5);
    }
        :global(:root) :global(.maxxl-columns-6) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 6);
        width: calc(var(--column-max-width, 100%) / 12 * 6);
    }
        :global(:root) :global(.maxxl-columns-7) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 7);
        width: calc(var(--column-max-width, 100%) / 12 * 7);
    }
        :global(:root) :global(.maxxl-columns-8) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 8);
        width: calc(var(--column-max-width, 100%) / 12 * 8);
    }
        :global(:root) :global(.maxxl-columns-9) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 9);
        width: calc(var(--column-max-width, 100%) / 12 * 9);
    }
        :global(:root) :global(.maxxl-columns-10) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 10);
        width: calc(var(--column-max-width, 100%) / 12 * 10);
    }
        :global(:root) :global(.maxxl-columns-11) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 11);
        width: calc(var(--column-max-width, 100%) / 12 * 11);
    }
        :global(:root) :global(.maxxl-columns-12) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(
        100vw - 5vw * 2
    );
        width: var(--column-max-width, 100%);
    }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-columns-1) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12);
        width: calc(var(--column-max-width, 100%) / 12);
    }
        :global(:root) :global(.onlys-columns-2) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 2);
        width: calc(var(--column-max-width, 100%) / 12 * 2);
    }
        :global(:root) :global(.onlys-columns-3) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 3);
        width: calc(var(--column-max-width, 100%) / 12 * 3);
    }
        :global(:root) :global(.onlys-columns-4) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 4);
        width: calc(var(--column-max-width, 100%) / 12 * 4);
    }
        :global(:root) :global(.onlys-columns-5) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 5);
        width: calc(var(--column-max-width, 100%) / 12 * 5);
    }
        :global(:root) :global(.onlys-columns-6) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 6);
        width: calc(var(--column-max-width, 100%) / 12 * 6);
    }
        :global(:root) :global(.onlys-columns-7) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 7);
        width: calc(var(--column-max-width, 100%) / 12 * 7);
    }
        :global(:root) :global(.onlys-columns-8) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 8);
        width: calc(var(--column-max-width, 100%) / 12 * 8);
    }
        :global(:root) :global(.onlys-columns-9) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 9);
        width: calc(var(--column-max-width, 100%) / 12 * 9);
    }
        :global(:root) :global(.onlys-columns-10) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 10);
        width: calc(var(--column-max-width, 100%) / 12 * 10);
    }
        :global(:root) :global(.onlys-columns-11) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 11);
        width: calc(var(--column-max-width, 100%) / 12 * 11);
    }
        :global(:root) :global(.onlys-columns-12) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(
        100vw - 5vw * 2
    );
        width: var(--column-max-width, 100%);
    }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-columns-1) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12);
        width: calc(var(--column-max-width, 100%) / 12);
    }
        :global(:root) :global(.onlym-columns-2) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 2);
        width: calc(var(--column-max-width, 100%) / 12 * 2);
    }
        :global(:root) :global(.onlym-columns-3) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 3);
        width: calc(var(--column-max-width, 100%) / 12 * 3);
    }
        :global(:root) :global(.onlym-columns-4) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 4);
        width: calc(var(--column-max-width, 100%) / 12 * 4);
    }
        :global(:root) :global(.onlym-columns-5) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 5);
        width: calc(var(--column-max-width, 100%) / 12 * 5);
    }
        :global(:root) :global(.onlym-columns-6) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 6);
        width: calc(var(--column-max-width, 100%) / 12 * 6);
    }
        :global(:root) :global(.onlym-columns-7) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 7);
        width: calc(var(--column-max-width, 100%) / 12 * 7);
    }
        :global(:root) :global(.onlym-columns-8) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 8);
        width: calc(var(--column-max-width, 100%) / 12 * 8);
    }
        :global(:root) :global(.onlym-columns-9) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 9);
        width: calc(var(--column-max-width, 100%) / 12 * 9);
    }
        :global(:root) :global(.onlym-columns-10) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 10);
        width: calc(var(--column-max-width, 100%) / 12 * 10);
    }
        :global(:root) :global(.onlym-columns-11) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 11);
        width: calc(var(--column-max-width, 100%) / 12 * 11);
    }
        :global(:root) :global(.onlym-columns-12) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(
        100vw - 5vw * 2
    );
        width: var(--column-max-width, 100%);
    }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-columns-1) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12);
        width: calc(var(--column-max-width, 100%) / 12);
    }
        :global(:root) :global(.onlyl-columns-2) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 2);
        width: calc(var(--column-max-width, 100%) / 12 * 2);
    }
        :global(:root) :global(.onlyl-columns-3) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 3);
        width: calc(var(--column-max-width, 100%) / 12 * 3);
    }
        :global(:root) :global(.onlyl-columns-4) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 4);
        width: calc(var(--column-max-width, 100%) / 12 * 4);
    }
        :global(:root) :global(.onlyl-columns-5) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 5);
        width: calc(var(--column-max-width, 100%) / 12 * 5);
    }
        :global(:root) :global(.onlyl-columns-6) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 6);
        width: calc(var(--column-max-width, 100%) / 12 * 6);
    }
        :global(:root) :global(.onlyl-columns-7) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 7);
        width: calc(var(--column-max-width, 100%) / 12 * 7);
    }
        :global(:root) :global(.onlyl-columns-8) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 8);
        width: calc(var(--column-max-width, 100%) / 12 * 8);
    }
        :global(:root) :global(.onlyl-columns-9) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 9);
        width: calc(var(--column-max-width, 100%) / 12 * 9);
    }
        :global(:root) :global(.onlyl-columns-10) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 10);
        width: calc(var(--column-max-width, 100%) / 12 * 10);
    }
        :global(:root) :global(.onlyl-columns-11) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(calc(
        100vw - 5vw * 2
    ) / 12 * 11);
        width: calc(var(--column-max-width, 100%) / 12 * 11);
    }
        :global(:root) :global(.onlyl-columns-12) {
        max-width: calc(
        100vw - 5vw * 2
    );
        max-width: var(--column-max-width);
        width: calc(
        100vw - 5vw * 2
    );
        width: var(--column-max-width, 100%);
    }
    }
@media (max-width: 575px) {
    :global(:root) :global(.columns-1) {
        width: calc(
            (calc(
        100vw - 5vw * 2
    ) - 5vw * 2) / 6
        );
        width: calc(
            (var(--column-max-width, 100%) - var(--layout-side-gutter) * 2) / 6
        );
    }
    :global(:root) :global(.columns-2) {
        width: calc(
            (calc(
        100vw - 5vw * 2
    ) - 5vw * 2) / 6 *
                2
        );
        width: calc(
            (var(--column-max-width, 100%) - var(--layout-side-gutter) * 2) / 6 *
                2
        );
    }
    :global(:root) :global(.columns-3) {
        width: calc(
            (calc(
        100vw - 5vw * 2
    ) - 5vw * 2) / 6 *
                3
        );
        width: calc(
            (var(--column-max-width, 100%) - var(--layout-side-gutter) * 2) / 6 *
                3
        );
    }
    :global(:root) :global(.columns-4) {
        width: calc(
            (calc(
        100vw - 5vw * 2
    ) - 5vw * 2) / 6 *
                4
        );
        width: calc(
            (var(--column-max-width, 100%) - var(--layout-side-gutter) * 2) / 6 *
                4
        );
    }
    :global(:root) :global(.columns-5) {
        width: calc(
            (calc(
        100vw - 5vw * 2
    ) - 5vw * 2) / 6 *
                5
        );
        width: calc(
            (var(--column-max-width, 100%) - var(--layout-side-gutter) * 2) / 6 *
                5
        );
    }
    :global(:root) :global(.columns-6) {
        width: calc(
            (calc(
        100vw - 5vw * 2
    ) - 5vw * 2) / 6 *
                6
        );
        width: calc(
            (var(--column-max-width, 100%) - var(--layout-side-gutter) * 2) / 6 *
                6
        );
    }
}
:global([class*='dimension-']) {
    display: relative;
    display: block;
}
:global([class*='dimension-']::after) {
    content: '';
    display: block;
}
:global(.dimension-1by1::after) {
        padding-bottom: 100%;
    }
:global(.dimension-4by3::after) {
        padding-bottom: 75%;
    }
:global(.dimension-16by9::after) {
        padding-bottom: calc(100% / 16 * 9);
    }
:global(.dimension-3by2::after) {
        padding-bottom: 66.66%;
    }
:global(.dimension-2by1::after) {
        padding-bottom: 50%;
    }
:global(.dimension-3by1::after) {
        padding-bottom: 33.33%;
    }
:global(.dimension-4by1::after) {
        padding-bottom: 25%;
    }
@media (min-width: 576px) {
        :global(.s-dimension-1by1::after) {
        padding-bottom: 100%;
    }
        :global(.s-dimension-4by3::after) {
        padding-bottom: 75%;
    }
        :global(.s-dimension-16by9::after) {
        padding-bottom: calc(100% / 16 * 9);
    }
        :global(.s-dimension-3by2::after) {
        padding-bottom: 66.66%;
    }
        :global(.s-dimension-2by1::after) {
        padding-bottom: 50%;
    }
        :global(.s-dimension-3by1::after) {
        padding-bottom: 33.33%;
    }
        :global(.s-dimension-4by1::after) {
        padding-bottom: 25%;
    }
    }
@media (min-width: 768px) {
        :global(.m-dimension-1by1::after) {
        padding-bottom: 100%;
    }
        :global(.m-dimension-4by3::after) {
        padding-bottom: 75%;
    }
        :global(.m-dimension-16by9::after) {
        padding-bottom: calc(100% / 16 * 9);
    }
        :global(.m-dimension-3by2::after) {
        padding-bottom: 66.66%;
    }
        :global(.m-dimension-2by1::after) {
        padding-bottom: 50%;
    }
        :global(.m-dimension-3by1::after) {
        padding-bottom: 33.33%;
    }
        :global(.m-dimension-4by1::after) {
        padding-bottom: 25%;
    }
    }
@media (min-width: 1024px) {
        :global(.l-dimension-1by1::after) {
        padding-bottom: 100%;
    }
        :global(.l-dimension-4by3::after) {
        padding-bottom: 75%;
    }
        :global(.l-dimension-16by9::after) {
        padding-bottom: calc(100% / 16 * 9);
    }
        :global(.l-dimension-3by2::after) {
        padding-bottom: 66.66%;
    }
        :global(.l-dimension-2by1::after) {
        padding-bottom: 50%;
    }
        :global(.l-dimension-3by1::after) {
        padding-bottom: 33.33%;
    }
        :global(.l-dimension-4by1::after) {
        padding-bottom: 25%;
    }
    }
@media (min-width: 1280px) {
        :global(.xl-dimension-1by1::after) {
        padding-bottom: 100%;
    }
        :global(.xl-dimension-4by3::after) {
        padding-bottom: 75%;
    }
        :global(.xl-dimension-16by9::after) {
        padding-bottom: calc(100% / 16 * 9);
    }
        :global(.xl-dimension-3by2::after) {
        padding-bottom: 66.66%;
    }
        :global(.xl-dimension-2by1::after) {
        padding-bottom: 50%;
    }
        :global(.xl-dimension-3by1::after) {
        padding-bottom: 33.33%;
    }
        :global(.xl-dimension-4by1::after) {
        padding-bottom: 25%;
    }
    }
@media (max-width: 575px) {
        :global(.maxs-dimension-1by1::after) {
        padding-bottom: 100%;
    }
        :global(.maxs-dimension-4by3::after) {
        padding-bottom: 75%;
    }
        :global(.maxs-dimension-16by9::after) {
        padding-bottom: calc(100% / 16 * 9);
    }
        :global(.maxs-dimension-3by2::after) {
        padding-bottom: 66.66%;
    }
        :global(.maxs-dimension-2by1::after) {
        padding-bottom: 50%;
    }
        :global(.maxs-dimension-3by1::after) {
        padding-bottom: 33.33%;
    }
        :global(.maxs-dimension-4by1::after) {
        padding-bottom: 25%;
    }
    }
@media (max-width: 767px) {
        :global(.maxm-dimension-1by1::after) {
        padding-bottom: 100%;
    }
        :global(.maxm-dimension-4by3::after) {
        padding-bottom: 75%;
    }
        :global(.maxm-dimension-16by9::after) {
        padding-bottom: calc(100% / 16 * 9);
    }
        :global(.maxm-dimension-3by2::after) {
        padding-bottom: 66.66%;
    }
        :global(.maxm-dimension-2by1::after) {
        padding-bottom: 50%;
    }
        :global(.maxm-dimension-3by1::after) {
        padding-bottom: 33.33%;
    }
        :global(.maxm-dimension-4by1::after) {
        padding-bottom: 25%;
    }
    }
@media (max-width: 1023px) {
        :global(.maxl-dimension-1by1::after) {
        padding-bottom: 100%;
    }
        :global(.maxl-dimension-4by3::after) {
        padding-bottom: 75%;
    }
        :global(.maxl-dimension-16by9::after) {
        padding-bottom: calc(100% / 16 * 9);
    }
        :global(.maxl-dimension-3by2::after) {
        padding-bottom: 66.66%;
    }
        :global(.maxl-dimension-2by1::after) {
        padding-bottom: 50%;
    }
        :global(.maxl-dimension-3by1::after) {
        padding-bottom: 33.33%;
    }
        :global(.maxl-dimension-4by1::after) {
        padding-bottom: 25%;
    }
    }
@media (min-width: 1279px) {
        :global(.maxxl-dimension-1by1::after) {
        padding-bottom: 100%;
    }
        :global(.maxxl-dimension-4by3::after) {
        padding-bottom: 75%;
    }
        :global(.maxxl-dimension-16by9::after) {
        padding-bottom: calc(100% / 16 * 9);
    }
        :global(.maxxl-dimension-3by2::after) {
        padding-bottom: 66.66%;
    }
        :global(.maxxl-dimension-2by1::after) {
        padding-bottom: 50%;
    }
        :global(.maxxl-dimension-3by1::after) {
        padding-bottom: 33.33%;
    }
        :global(.maxxl-dimension-4by1::after) {
        padding-bottom: 25%;
    }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(.onlys-dimension-1by1::after) {
        padding-bottom: 100%;
    }
        :global(.onlys-dimension-4by3::after) {
        padding-bottom: 75%;
    }
        :global(.onlys-dimension-16by9::after) {
        padding-bottom: calc(100% / 16 * 9);
    }
        :global(.onlys-dimension-3by2::after) {
        padding-bottom: 66.66%;
    }
        :global(.onlys-dimension-2by1::after) {
        padding-bottom: 50%;
    }
        :global(.onlys-dimension-3by1::after) {
        padding-bottom: 33.33%;
    }
        :global(.onlys-dimension-4by1::after) {
        padding-bottom: 25%;
    }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(.onlym-dimension-1by1::after) {
        padding-bottom: 100%;
    }
        :global(.onlym-dimension-4by3::after) {
        padding-bottom: 75%;
    }
        :global(.onlym-dimension-16by9::after) {
        padding-bottom: calc(100% / 16 * 9);
    }
        :global(.onlym-dimension-3by2::after) {
        padding-bottom: 66.66%;
    }
        :global(.onlym-dimension-2by1::after) {
        padding-bottom: 50%;
    }
        :global(.onlym-dimension-3by1::after) {
        padding-bottom: 33.33%;
    }
        :global(.onlym-dimension-4by1::after) {
        padding-bottom: 25%;
    }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(.onlyl-dimension-1by1::after) {
        padding-bottom: 100%;
    }
        :global(.onlyl-dimension-4by3::after) {
        padding-bottom: 75%;
    }
        :global(.onlyl-dimension-16by9::after) {
        padding-bottom: calc(100% / 16 * 9);
    }
        :global(.onlyl-dimension-3by2::after) {
        padding-bottom: 66.66%;
    }
        :global(.onlyl-dimension-2by1::after) {
        padding-bottom: 50%;
    }
        :global(.onlyl-dimension-3by1::after) {
        padding-bottom: 33.33%;
    }
        :global(.onlyl-dimension-4by1::after) {
        padding-bottom: 25%;
    }
    }
:global(:root) {
    --font-family: 'KommonGrotesk', BlinkMacSystemFont, -apple-system,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-bold: 600;
}
:global(:root) :global(.font-weight-light) {
    font-weight: 300;
    font-weight: var(--font-weight-light);
}
:global(:root) :global(.font-weight-normal) {
    font-weight: 400;
    font-weight: var(--font-weight-normal);
}
:global(:root) :global(.font-weight-bold) {
    font-weight: 600;
    font-weight: var(--font-weight-bold);
}
@font-face {
    font-family: 'KommonGrotesk';
    font-weight: 300;
    font-style: normal;
    src: url('/fonts/3AD50F_0_0.eot');
    src: url('/fonts/3AD50F_0_0.eot?#iefix') format('embedded-opentype'),
        url('/fonts/3AD50F_0_0.woff2') format('woff2'),
        url('/fonts/3AD50F_0_0.woff') format('woff'),
        url('/fonts/3AD50F_0_0.ttf') format('truetype');
}
@font-face {
    font-family: 'KommonGrotesk';
    font-weight: 400;
    font-style: normal;
    src: url('/fonts/3AD50F_1_0.eot');
    src: url('/fonts/3AD50F_1_0.eot?#iefix') format('embedded-opentype'),
        url('/fonts/3AD50F_1_0.woff2') format('woff2'),
        url('/fonts/3AD50F_1_0.woff') format('woff'),
        url('/fonts/3AD50F_1_0.ttf') format('truetype');
}
@font-face {
    font-family: 'KommonGrotesk';
    font-weight: 600;
    font-style: normal;
    src: url('/fonts/3AD50F_2_0.eot');
    src: url('/fonts/3AD50F_2_0.eot?#iefix') format('embedded-opentype'),
        url('/fonts/3AD50F_2_0.woff2') format('woff2'),
        url('/fonts/3AD50F_2_0.woff') format('woff'),
        url('/fonts/3AD50F_2_0.ttf') format('truetype');
}
:global(.hide) {
    display: none;
}
:global(:root) :global(.hidden) {
        display: none;
    }
:global(:root) :global(.hidden-text) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-hidden) {
        display: none;
    }
        :global(:root) :global(.s-hidden-text) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-hidden) {
        display: none;
    }
        :global(:root) :global(.m-hidden-text) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-hidden) {
        display: none;
    }
        :global(:root) :global(.l-hidden-text) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-hidden) {
        display: none;
    }
        :global(:root) :global(.xl-hidden-text) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-hidden) {
        display: none;
    }
        :global(:root) :global(.maxs-hidden-text) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-hidden) {
        display: none;
    }
        :global(:root) :global(.maxm-hidden-text) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-hidden) {
        display: none;
    }
        :global(:root) :global(.maxl-hidden-text) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-hidden) {
        display: none;
    }
        :global(:root) :global(.maxxl-hidden-text) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-hidden) {
        display: none;
    }
        :global(:root) :global(.onlys-hidden-text) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-hidden) {
        display: none;
    }
        :global(:root) :global(.onlym-hidden-text) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-hidden) {
        display: none;
    }
        :global(:root) :global(.onlyl-hidden-text) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }
    }
:global(:root) {
    --layout-side-gutter: 5vw;
    --layout-max-width: 100vw;
    --layout-content-width: calc(
        var(--layout-max-width) - var(--layout-side-gutter) * 2
    );
    --layout-min-content-height: 100vh;
    --layout-gutter: 2rem;
    --block-vertical-padding: calc(var(--layout-gutter, 2em) * 2);
}
@media (min-width: 1449px) {
    :global(:root) {
        --layout-content-width: 1304px;
    }
}
:global(.layout-container) {
    padding-left: 2rem;
    padding-left: var(--layout-gutter);
    padding-right: 2rem;
    padding-right: var(--layout-gutter);
}
:global(.layout-center) {
    margin-left: auto;
    margin-right: auto;
}
:global(.layout-vertical-padding) {
    padding-top: 2rem;
    padding-top: var(--layout-gutter);
    padding-bottom: 2rem;
    padding-bottom: var(--layout-gutter);
}
:global(.layout-padding) {
    padding-left: 2rem;
    padding-left: var(--layout-gutter);
    padding-right: 2rem;
    padding-right: var(--layout-gutter);
}
:global(.layout-fill) {
    max-width: 100vw;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
:global(:root) :global(.no-topMargin) {
        margin-top: 0;
    }
:global(:root) :global(.no-bottomMargin) {
        margin-bottom: 0;
    }
:global(:root) :global(.no-margin) {
        margin: 0;
    }
:global(:root) :global(.no-padding) {
        padding: 0;
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-no-topMargin) {
        margin-top: 0;
    }
        :global(:root) :global(.s-no-bottomMargin) {
        margin-bottom: 0;
    }
        :global(:root) :global(.s-no-margin) {
        margin: 0;
    }
        :global(:root) :global(.s-no-padding) {
        padding: 0;
    }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-no-topMargin) {
        margin-top: 0;
    }
        :global(:root) :global(.m-no-bottomMargin) {
        margin-bottom: 0;
    }
        :global(:root) :global(.m-no-margin) {
        margin: 0;
    }
        :global(:root) :global(.m-no-padding) {
        padding: 0;
    }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-no-topMargin) {
        margin-top: 0;
    }
        :global(:root) :global(.l-no-bottomMargin) {
        margin-bottom: 0;
    }
        :global(:root) :global(.l-no-margin) {
        margin: 0;
    }
        :global(:root) :global(.l-no-padding) {
        padding: 0;
    }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-no-topMargin) {
        margin-top: 0;
    }
        :global(:root) :global(.xl-no-bottomMargin) {
        margin-bottom: 0;
    }
        :global(:root) :global(.xl-no-margin) {
        margin: 0;
    }
        :global(:root) :global(.xl-no-padding) {
        padding: 0;
    }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-no-topMargin) {
        margin-top: 0;
    }
        :global(:root) :global(.maxs-no-bottomMargin) {
        margin-bottom: 0;
    }
        :global(:root) :global(.maxs-no-margin) {
        margin: 0;
    }
        :global(:root) :global(.maxs-no-padding) {
        padding: 0;
    }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-no-topMargin) {
        margin-top: 0;
    }
        :global(:root) :global(.maxm-no-bottomMargin) {
        margin-bottom: 0;
    }
        :global(:root) :global(.maxm-no-margin) {
        margin: 0;
    }
        :global(:root) :global(.maxm-no-padding) {
        padding: 0;
    }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-no-topMargin) {
        margin-top: 0;
    }
        :global(:root) :global(.maxl-no-bottomMargin) {
        margin-bottom: 0;
    }
        :global(:root) :global(.maxl-no-margin) {
        margin: 0;
    }
        :global(:root) :global(.maxl-no-padding) {
        padding: 0;
    }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-no-topMargin) {
        margin-top: 0;
    }
        :global(:root) :global(.maxxl-no-bottomMargin) {
        margin-bottom: 0;
    }
        :global(:root) :global(.maxxl-no-margin) {
        margin: 0;
    }
        :global(:root) :global(.maxxl-no-padding) {
        padding: 0;
    }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-no-topMargin) {
        margin-top: 0;
    }
        :global(:root) :global(.onlys-no-bottomMargin) {
        margin-bottom: 0;
    }
        :global(:root) :global(.onlys-no-margin) {
        margin: 0;
    }
        :global(:root) :global(.onlys-no-padding) {
        padding: 0;
    }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-no-topMargin) {
        margin-top: 0;
    }
        :global(:root) :global(.onlym-no-bottomMargin) {
        margin-bottom: 0;
    }
        :global(:root) :global(.onlym-no-margin) {
        margin: 0;
    }
        :global(:root) :global(.onlym-no-padding) {
        padding: 0;
    }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-no-topMargin) {
        margin-top: 0;
    }
        :global(:root) :global(.onlyl-no-bottomMargin) {
        margin-bottom: 0;
    }
        :global(:root) :global(.onlyl-no-margin) {
        margin: 0;
    }
        :global(:root) :global(.onlyl-no-padding) {
        padding: 0;
    }
    }
:global(:root) {
    --box-shadow: 0 0.75rem 1.5rem 0 rgba(0, 0, 0, 0.16);
}
:global(:root) {
    --text-size-heading1-hero: 3.6875rem;
    --text-size-heading1: 3rem;
    --text-size-heading2: 2.5rem;
    --text-size-huge: 2rem;
    --text-size-extra-large: 1.5rem;
    --text-size-large: 1.25rem;
    --text-size-normal: 1rem;
    --text-size-small: 0.75rem;
    --text-size-base: 16px;

    --text-size-quote: normal 300 var(--text-size-heading2) var(--font-family);

    --text-normal: normal 300 var(--text-size-normal) / 1.5 var(--font-family);
    --text-heading1: normal 300 var(--text-size-heading1) var(--font-family);
    --text-heading2: normal 300 var(--text-size-heading2) var(--font-family);
    --text-heading3: normal 400 var(--text-size-huge) var(--font-family);
    --text-heading4: normal 400 var(--text-size-extra-large) var(--font-family);
    --text-heading5: normal 600 var(--text-size-large) var(--font-family);
}
@media (max-width: 575px) {
    :global(:root) {
        --text-size-base: 14px;
        --text-size-heading1-hero: 1.85rem;
        --text-size-small: 0.75rem;
        --text-size-normal: 0.875rem;
        --text-size-large: 1rem;
        --text-size-extra-large: 1.25rem;
        --text-size-huge: 1.5rem;
        --text-size-heading2: 1.5rem;
        --text-size-heading1: 1.625rem;
    }
}
:global(:root) :global(.text-normal) {
    font: normal 300 1rem / 1.5 'KommonGrotesk', BlinkMacSystemFont, -apple-system,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font: var(--text-normal);
}
:global(:root) :global(.text-heading1) {
    font: normal 300 3rem 'KommonGrotesk', BlinkMacSystemFont, -apple-system,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font: var(--text-heading1);
}
:global(:root) :global(.text-heading2) {
    font: normal 300 2.5rem 'KommonGrotesk', BlinkMacSystemFont, -apple-system,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font: var(--text-heading2);
}
:global(:root) :global(.text-heading3) {
    font: normal 400 2rem 'KommonGrotesk', BlinkMacSystemFont, -apple-system,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font: var(--text-heading3);
}
:global(:root) :global(.text-heading4) {
    font: normal 400 1.5rem 'KommonGrotesk', BlinkMacSystemFont, -apple-system,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font: var(--text-heading4);
}
:global(:root) :global(.text-heading5) {
    font: normal 600 1.25rem 'KommonGrotesk', BlinkMacSystemFont, -apple-system,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font: var(--text-heading5);
}
:global(:root) :global(.text-size-small) {
    font-size: 0.75rem;
    font-size: var(--text-size-small);
}
:global(:root) :global(.text-size-normal) {
    font-size: 1rem;
    font-size: var(--text-size-normal);
}
:global(:root) :global(.text-size-large) {
    font-size: 1.25rem;
    font-size: var(--text-size-large);
}
:global(:root) :global(.text-size-extra-large) {
    font-size: 1.5rem;
    font-size: var(--text-size-extra-large);
}
:global(:root) :global(.text-size-huge) {
    font-size: 2rem;
    font-size: var(--text-size-huge);
}
:global(:root) :global(.text-align-center) {
    text-align: center;
}
:global(:root) :global(.text-align-left) {
    text-align: left;
}
:global(:root) :global(.text-align-right) {
    text-align: right;
}
:global(.text-columns-1) {
        -moz-column-count: 1;
             column-count: 1;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
:global(.text-columns-2) {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
:global(.text-columns-3) {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
:global(.text-columns-4) {
        -moz-column-count: 4;
             column-count: 4;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
:global(.text-columns-5) {
        -moz-column-count: 5;
             column-count: 5;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
@media (min-width: 576px) {
        :global(.s-text-columns-1) {
        -moz-column-count: 1;
             column-count: 1;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.s-text-columns-2) {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.s-text-columns-3) {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.s-text-columns-4) {
        -moz-column-count: 4;
             column-count: 4;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.s-text-columns-5) {
        -moz-column-count: 5;
             column-count: 5;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
    }
@media (min-width: 768px) {
        :global(.m-text-columns-1) {
        -moz-column-count: 1;
             column-count: 1;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.m-text-columns-2) {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.m-text-columns-3) {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.m-text-columns-4) {
        -moz-column-count: 4;
             column-count: 4;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.m-text-columns-5) {
        -moz-column-count: 5;
             column-count: 5;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
    }
@media (min-width: 1024px) {
        :global(.l-text-columns-1) {
        -moz-column-count: 1;
             column-count: 1;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.l-text-columns-2) {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.l-text-columns-3) {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.l-text-columns-4) {
        -moz-column-count: 4;
             column-count: 4;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.l-text-columns-5) {
        -moz-column-count: 5;
             column-count: 5;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
    }
@media (min-width: 1280px) {
        :global(.xl-text-columns-1) {
        -moz-column-count: 1;
             column-count: 1;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.xl-text-columns-2) {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.xl-text-columns-3) {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.xl-text-columns-4) {
        -moz-column-count: 4;
             column-count: 4;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.xl-text-columns-5) {
        -moz-column-count: 5;
             column-count: 5;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
    }
@media (max-width: 575px) {
        :global(.maxs-text-columns-1) {
        -moz-column-count: 1;
             column-count: 1;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxs-text-columns-2) {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxs-text-columns-3) {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxs-text-columns-4) {
        -moz-column-count: 4;
             column-count: 4;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxs-text-columns-5) {
        -moz-column-count: 5;
             column-count: 5;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
    }
@media (max-width: 767px) {
        :global(.maxm-text-columns-1) {
        -moz-column-count: 1;
             column-count: 1;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxm-text-columns-2) {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxm-text-columns-3) {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxm-text-columns-4) {
        -moz-column-count: 4;
             column-count: 4;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxm-text-columns-5) {
        -moz-column-count: 5;
             column-count: 5;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
    }
@media (max-width: 1023px) {
        :global(.maxl-text-columns-1) {
        -moz-column-count: 1;
             column-count: 1;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxl-text-columns-2) {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxl-text-columns-3) {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxl-text-columns-4) {
        -moz-column-count: 4;
             column-count: 4;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxl-text-columns-5) {
        -moz-column-count: 5;
             column-count: 5;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
    }
@media (min-width: 1279px) {
        :global(.maxxl-text-columns-1) {
        -moz-column-count: 1;
             column-count: 1;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxxl-text-columns-2) {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxxl-text-columns-3) {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxxl-text-columns-4) {
        -moz-column-count: 4;
             column-count: 4;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.maxxl-text-columns-5) {
        -moz-column-count: 5;
             column-count: 5;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(.onlys-text-columns-1) {
        -moz-column-count: 1;
             column-count: 1;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.onlys-text-columns-2) {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.onlys-text-columns-3) {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.onlys-text-columns-4) {
        -moz-column-count: 4;
             column-count: 4;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.onlys-text-columns-5) {
        -moz-column-count: 5;
             column-count: 5;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(.onlym-text-columns-1) {
        -moz-column-count: 1;
             column-count: 1;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.onlym-text-columns-2) {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.onlym-text-columns-3) {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.onlym-text-columns-4) {
        -moz-column-count: 4;
             column-count: 4;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.onlym-text-columns-5) {
        -moz-column-count: 5;
             column-count: 5;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(.onlyl-text-columns-1) {
        -moz-column-count: 1;
             column-count: 1;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.onlyl-text-columns-2) {
        -moz-column-count: 2;
             column-count: 2;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.onlyl-text-columns-3) {
        -moz-column-count: 3;
             column-count: 3;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.onlyl-text-columns-4) {
        -moz-column-count: 4;
             column-count: 4;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
        :global(.onlyl-text-columns-5) {
        -moz-column-count: 5;
             column-count: 5;
        grid-column-gap: 2rem;
        -moz-column-gap: 2rem;
             column-gap: 2rem;
        grid-column-gap: var(--layout-gutter);
        -moz-column-gap: var(--layout-gutter);
             column-gap: var(--layout-gutter);
    }
    }
:global(kbd) {
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
    color: #333;
    background-color: #fff;
    display: inline-block;
    font-family: Consolas,Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,monospace,sans-serif;
    line-height: 1;
    font-size: 0.9em;
    padding: 2px 4px;
    text-shadow: 0 1px 0 #fff;
    white-space: nowrap;
}
:global(code) {
    font-size: 13px;
    font-family: Consolas,Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,monospace,sans-serif;
    padding: 2px 4px;
    background-color: #eee;
    border-radius: 3px;
}
:global(:root) :global(.theme-dark-blue) {
        background-color: var(--color-dark-blue);
        color: var(--color-white);
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-theme-dark-blue) {
        background-color: var(--color-dark-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-theme-dark-blue) {
        background-color: var(--color-dark-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-theme-dark-blue) {
        background-color: var(--color-dark-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-theme-dark-blue) {
        background-color: var(--color-dark-blue);
        color: var(--color-white);
        }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-theme-dark-blue) {
        background-color: var(--color-dark-blue);
        color: var(--color-white);
        }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-theme-dark-blue) {
        background-color: var(--color-dark-blue);
        color: var(--color-white);
        }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-theme-dark-blue) {
        background-color: var(--color-dark-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-theme-dark-blue) {
        background-color: var(--color-dark-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-theme-dark-blue) {
        background-color: var(--color-dark-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-theme-dark-blue) {
        background-color: var(--color-dark-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-theme-dark-blue) {
        background-color: var(--color-dark-blue);
        color: var(--color-white);
        }
    }
:global(:root) :global(.theme-dark-blue-light) {
        background-color: var(--color-white);
        color: var(--color-dark-blue);
            border: 1px solid var(--color-dark-blue);
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-theme-dark-blue-light) {
        background-color: var(--color-white);
        color: var(--color-dark-blue);
            border: 1px solid var(--color-dark-blue);
        }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-theme-dark-blue-light) {
        background-color: var(--color-white);
        color: var(--color-dark-blue);
            border: 1px solid var(--color-dark-blue);
        }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-theme-dark-blue-light) {
        background-color: var(--color-white);
        color: var(--color-dark-blue);
            border: 1px solid var(--color-dark-blue);
        }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-theme-dark-blue-light) {
        background-color: var(--color-white);
        color: var(--color-dark-blue);
            border: 1px solid var(--color-dark-blue);
        }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-theme-dark-blue-light) {
        background-color: var(--color-white);
        color: var(--color-dark-blue);
            border: 1px solid var(--color-dark-blue);
        }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-theme-dark-blue-light) {
        background-color: var(--color-white);
        color: var(--color-dark-blue);
            border: 1px solid var(--color-dark-blue);
        }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-theme-dark-blue-light) {
        background-color: var(--color-white);
        color: var(--color-dark-blue);
            border: 1px solid var(--color-dark-blue);
        }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-theme-dark-blue-light) {
        background-color: var(--color-white);
        color: var(--color-dark-blue);
            border: 1px solid var(--color-dark-blue);
        }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-theme-dark-blue-light) {
        background-color: var(--color-white);
        color: var(--color-dark-blue);
            border: 1px solid var(--color-dark-blue);
        }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-theme-dark-blue-light) {
        background-color: var(--color-white);
        color: var(--color-dark-blue);
            border: 1px solid var(--color-dark-blue);
        }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-theme-dark-blue-light) {
        background-color: var(--color-white);
        color: var(--color-dark-blue);
            border: 1px solid var(--color-dark-blue);
        }
    }
:global(:root) :global(.theme-darker-blue) {
        background-color: var(--color-darker-blue);
        color: var(--color-white);
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-theme-darker-blue) {
        background-color: var(--color-darker-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-theme-darker-blue) {
        background-color: var(--color-darker-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-theme-darker-blue) {
        background-color: var(--color-darker-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-theme-darker-blue) {
        background-color: var(--color-darker-blue);
        color: var(--color-white);
        }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-theme-darker-blue) {
        background-color: var(--color-darker-blue);
        color: var(--color-white);
        }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-theme-darker-blue) {
        background-color: var(--color-darker-blue);
        color: var(--color-white);
        }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-theme-darker-blue) {
        background-color: var(--color-darker-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-theme-darker-blue) {
        background-color: var(--color-darker-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-theme-darker-blue) {
        background-color: var(--color-darker-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-theme-darker-blue) {
        background-color: var(--color-darker-blue);
        color: var(--color-white);
        }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-theme-darker-blue) {
        background-color: var(--color-darker-blue);
        color: var(--color-white);
        }
    }
:global(:root) :global(.theme-pink) {
        background-color: var(--color-pink);
        color: var(--color-text);
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-theme-pink) {
        background-color: var(--color-pink);
        color: var(--color-text);
        }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-theme-pink) {
        background-color: var(--color-pink);
        color: var(--color-text);
        }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-theme-pink) {
        background-color: var(--color-pink);
        color: var(--color-text);
        }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-theme-pink) {
        background-color: var(--color-pink);
        color: var(--color-text);
        }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-theme-pink) {
        background-color: var(--color-pink);
        color: var(--color-text);
        }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-theme-pink) {
        background-color: var(--color-pink);
        color: var(--color-text);
        }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-theme-pink) {
        background-color: var(--color-pink);
        color: var(--color-text);
        }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-theme-pink) {
        background-color: var(--color-pink);
        color: var(--color-text);
        }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-theme-pink) {
        background-color: var(--color-pink);
        color: var(--color-text);
        }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-theme-pink) {
        background-color: var(--color-pink);
        color: var(--color-text);
        }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-theme-pink) {
        background-color: var(--color-pink);
        color: var(--color-text);
        }
    }
:global(:root) :global(.theme-turquoise) {
        background-color: var(--color-turquoise);
        color: var(--color-text);
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-theme-turquoise) {
        background-color: var(--color-turquoise);
        color: var(--color-text);
        }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-theme-turquoise) {
        background-color: var(--color-turquoise);
        color: var(--color-text);
        }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-theme-turquoise) {
        background-color: var(--color-turquoise);
        color: var(--color-text);
        }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-theme-turquoise) {
        background-color: var(--color-turquoise);
        color: var(--color-text);
        }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-theme-turquoise) {
        background-color: var(--color-turquoise);
        color: var(--color-text);
        }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-theme-turquoise) {
        background-color: var(--color-turquoise);
        color: var(--color-text);
        }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-theme-turquoise) {
        background-color: var(--color-turquoise);
        color: var(--color-text);
        }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-theme-turquoise) {
        background-color: var(--color-turquoise);
        color: var(--color-text);
        }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-theme-turquoise) {
        background-color: var(--color-turquoise);
        color: var(--color-text);
        }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-theme-turquoise) {
        background-color: var(--color-turquoise);
        color: var(--color-text);
        }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-theme-turquoise) {
        background-color: var(--color-turquoise);
        color: var(--color-text);
        }
    }
:global(:root) :global(.theme-white) {
        background-color: var(--color-white);
        color: var(--color-text);
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-theme-white) {
        background-color: var(--color-white);
        color: var(--color-text);
        }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-theme-white) {
        background-color: var(--color-white);
        color: var(--color-text);
        }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-theme-white) {
        background-color: var(--color-white);
        color: var(--color-text);
        }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-theme-white) {
        background-color: var(--color-white);
        color: var(--color-text);
        }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-theme-white) {
        background-color: var(--color-white);
        color: var(--color-text);
        }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-theme-white) {
        background-color: var(--color-white);
        color: var(--color-text);
        }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-theme-white) {
        background-color: var(--color-white);
        color: var(--color-text);
        }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-theme-white) {
        background-color: var(--color-white);
        color: var(--color-text);
        }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-theme-white) {
        background-color: var(--color-white);
        color: var(--color-text);
        }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-theme-white) {
        background-color: var(--color-white);
        color: var(--color-text);
        }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-theme-white) {
        background-color: var(--color-white);
        color: var(--color-text);
        }
    }
:global(:root) :global(.theme-black) {
        background-color: var(--color-black);
        color: var(--color-white);
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-theme-black) {
        background-color: var(--color-black);
        color: var(--color-white);
        }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-theme-black) {
        background-color: var(--color-black);
        color: var(--color-white);
        }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-theme-black) {
        background-color: var(--color-black);
        color: var(--color-white);
        }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-theme-black) {
        background-color: var(--color-black);
        color: var(--color-white);
        }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-theme-black) {
        background-color: var(--color-black);
        color: var(--color-white);
        }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-theme-black) {
        background-color: var(--color-black);
        color: var(--color-white);
        }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-theme-black) {
        background-color: var(--color-black);
        color: var(--color-white);
        }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-theme-black) {
        background-color: var(--color-black);
        color: var(--color-white);
        }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-theme-black) {
        background-color: var(--color-black);
        color: var(--color-white);
        }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-theme-black) {
        background-color: var(--color-black);
        color: var(--color-white);
        }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-theme-black) {
        background-color: var(--color-black);
        color: var(--color-white);
        }
    }
:global(:root) :global(.theme-blue-main) {
        background-color: var(--color-blue-main);
        color: var(--color-white);
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-theme-blue-main) {
        background-color: var(--color-blue-main);
        color: var(--color-white);
        }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-theme-blue-main) {
        background-color: var(--color-blue-main);
        color: var(--color-white);
        }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-theme-blue-main) {
        background-color: var(--color-blue-main);
        color: var(--color-white);
        }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-theme-blue-main) {
        background-color: var(--color-blue-main);
        color: var(--color-white);
        }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-theme-blue-main) {
        background-color: var(--color-blue-main);
        color: var(--color-white);
        }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-theme-blue-main) {
        background-color: var(--color-blue-main);
        color: var(--color-white);
        }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-theme-blue-main) {
        background-color: var(--color-blue-main);
        color: var(--color-white);
        }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-theme-blue-main) {
        background-color: var(--color-blue-main);
        color: var(--color-white);
        }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-theme-blue-main) {
        background-color: var(--color-blue-main);
        color: var(--color-white);
        }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-theme-blue-main) {
        background-color: var(--color-blue-main);
        color: var(--color-white);
        }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-theme-blue-main) {
        background-color: var(--color-blue-main);
        color: var(--color-white);
        }
    }
:global(:root) :global(.theme-turquoise-main) {
        background-color: var(--color-turquoise-main);
        color: var(--color-blue-main);
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-theme-turquoise-main) {
        background-color: var(--color-turquoise-main);
        color: var(--color-blue-main);
        }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-theme-turquoise-main) {
        background-color: var(--color-turquoise-main);
        color: var(--color-blue-main);
        }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-theme-turquoise-main) {
        background-color: var(--color-turquoise-main);
        color: var(--color-blue-main);
        }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-theme-turquoise-main) {
        background-color: var(--color-turquoise-main);
        color: var(--color-blue-main);
        }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-theme-turquoise-main) {
        background-color: var(--color-turquoise-main);
        color: var(--color-blue-main);
        }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-theme-turquoise-main) {
        background-color: var(--color-turquoise-main);
        color: var(--color-blue-main);
        }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-theme-turquoise-main) {
        background-color: var(--color-turquoise-main);
        color: var(--color-blue-main);
        }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-theme-turquoise-main) {
        background-color: var(--color-turquoise-main);
        color: var(--color-blue-main);
        }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-theme-turquoise-main) {
        background-color: var(--color-turquoise-main);
        color: var(--color-blue-main);
        }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-theme-turquoise-main) {
        background-color: var(--color-turquoise-main);
        color: var(--color-blue-main);
        }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-theme-turquoise-main) {
        background-color: var(--color-turquoise-main);
        color: var(--color-blue-main);
        }
    }
:global(:root) :global(.theme-pink-main) :global(pink-main) {
        background-color: var(--color-blue-main);
        color: var(--color-);
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-theme-pink-main) :global(pink-main) {
        background-color: var(--color-blue-main);
        color: var(--color-);
        }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-theme-pink-main) :global(pink-main) {
        background-color: var(--color-blue-main);
        color: var(--color-);
        }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-theme-pink-main) :global(pink-main) {
        background-color: var(--color-blue-main);
        color: var(--color-);
        }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-theme-pink-main) :global(pink-main) {
        background-color: var(--color-blue-main);
        color: var(--color-);
        }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-theme-pink-main) :global(pink-main) {
        background-color: var(--color-blue-main);
        color: var(--color-);
        }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-theme-pink-main) :global(pink-main) {
        background-color: var(--color-blue-main);
        color: var(--color-);
        }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-theme-pink-main) :global(pink-main) {
        background-color: var(--color-blue-main);
        color: var(--color-);
        }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-theme-pink-main) :global(pink-main) {
        background-color: var(--color-blue-main);
        color: var(--color-);
        }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-theme-pink-main) :global(pink-main) {
        background-color: var(--color-blue-main);
        color: var(--color-);
        }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-theme-pink-main) :global(pink-main) {
        background-color: var(--color-blue-main);
        color: var(--color-);
        }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-theme-pink-main) :global(pink-main) {
        background-color: var(--color-blue-main);
        color: var(--color-);
        }
    }
:global(.theme-blue-main.Button:hover) {
    background-color: #0206a3;
}
:global(.theme-blue-main.Button:active) {
    background-color: #010470;
}
:global(.theme-turquoise-main.Button:hover) {
    background-color: #a3e0e3;
}
:global(.theme-turquoise-main.Button:active) {
    background-color: #cffbfd;
}
:global(.theme-pink-main.Button:hover) {
    background-color: #d690c6;
}
:global(.theme-pink-main.Button:active) {
    background-color: #f6c7eb;
}
:global(:root) :global(.theme-light-grey) {
        background-color: var(--color-light-grey);
        color: var(--color-dark-blue);
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-theme-light-grey) {
        background-color: var(--color-light-grey);
        color: var(--color-dark-blue);
        }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-theme-light-grey) {
        background-color: var(--color-light-grey);
        color: var(--color-dark-blue);
        }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-theme-light-grey) {
        background-color: var(--color-light-grey);
        color: var(--color-dark-blue);
        }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-theme-light-grey) {
        background-color: var(--color-light-grey);
        color: var(--color-dark-blue);
        }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-theme-light-grey) {
        background-color: var(--color-light-grey);
        color: var(--color-dark-blue);
        }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-theme-light-grey) {
        background-color: var(--color-light-grey);
        color: var(--color-dark-blue);
        }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-theme-light-grey) {
        background-color: var(--color-light-grey);
        color: var(--color-dark-blue);
        }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-theme-light-grey) {
        background-color: var(--color-light-grey);
        color: var(--color-dark-blue);
        }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-theme-light-grey) {
        background-color: var(--color-light-grey);
        color: var(--color-dark-blue);
        }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-theme-light-grey) {
        background-color: var(--color-light-grey);
        color: var(--color-dark-blue);
        }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-theme-light-grey) {
        background-color: var(--color-light-grey);
        color: var(--color-dark-blue);
        }
    }
:global(:root) {
}
:global(:root) :global(.width-1of1),
    :global(:root) :global(.width-2of2),
    :global(:root) :global(.width-3of3),
    :global(:root) :global(.width-4of4),
    :global(:root) :global(.width-5of5),
    :global(:root) :global(.width-6of6) {
        width: 100%;
    }
:global(:root) :global(.width-5of6) {
        width: calc(100% / 6 * 5); /* 83.33% */
    }
:global(:root) :global(.width-4of5) {
        width: 80%;
    }
:global(:root) :global(.width-3of4) {
        width: 75%;
    }
:global(:root) :global(.width-4of6),
    :global(:root) :global(.width-2of3) {
        width: calc(100% / 6 * 4); /* 66.66 */
    }
:global(:root) :global(.width-3of5) {
        width: 60%;
    }
:global(:root) :global(.width-3of6),
    :global(:root) :global(.width-2of4),
    :global(:root) :global(.width-1of2) {
        width: 50%;
    }
:global(:root) :global(.width-2of5) {
        width: 40%;
    }
:global(:root) :global(.width-2of6),
    :global(:root) :global(.width-1of3) {
        width: calc(100% / 3); /* 33.33% */
    }
:global(:root) :global(.width-1of4) {
        width: 25%;
    }
:global(:root) :global(.width-1of5) {
        width: 20%;
    }
:global(:root) :global(.width-1of6) {
        width: calc(100% / 6); /* 16.66 */
    }
@media (min-width: 576px) {
        :global(:root) :global(.s-width-1of1),
    :global(:root) :global(.s-width-2of2),
    :global(:root) :global(.s-width-3of3),
    :global(:root) :global(.s-width-4of4),
    :global(:root) :global(.s-width-5of5),
    :global(:root) :global(.s-width-6of6) {
        width: 100%;
    }
        :global(:root) :global(.s-width-5of6) {
        width: calc(100% / 6 * 5); /* 83.33% */
    }
        :global(:root) :global(.s-width-4of5) {
        width: 80%;
    }
        :global(:root) :global(.s-width-3of4) {
        width: 75%;
    }
        :global(:root) :global(.s-width-4of6),
    :global(:root) :global(.s-width-2of3) {
        width: calc(100% / 6 * 4); /* 66.66 */
    }
        :global(:root) :global(.s-width-3of5) {
        width: 60%;
    }
        :global(:root) :global(.s-width-3of6),
    :global(:root) :global(.s-width-2of4),
    :global(:root) :global(.s-width-1of2) {
        width: 50%;
    }
        :global(:root) :global(.s-width-2of5) {
        width: 40%;
    }
        :global(:root) :global(.s-width-2of6),
    :global(:root) :global(.s-width-1of3) {
        width: calc(100% / 3); /* 33.33% */
    }
        :global(:root) :global(.s-width-1of4) {
        width: 25%;
    }
        :global(:root) :global(.s-width-1of5) {
        width: 20%;
    }
        :global(:root) :global(.s-width-1of6) {
        width: calc(100% / 6); /* 16.66 */
    }
    }
@media (min-width: 768px) {
        :global(:root) :global(.m-width-1of1),
    :global(:root) :global(.m-width-2of2),
    :global(:root) :global(.m-width-3of3),
    :global(:root) :global(.m-width-4of4),
    :global(:root) :global(.m-width-5of5),
    :global(:root) :global(.m-width-6of6) {
        width: 100%;
    }
        :global(:root) :global(.m-width-5of6) {
        width: calc(100% / 6 * 5); /* 83.33% */
    }
        :global(:root) :global(.m-width-4of5) {
        width: 80%;
    }
        :global(:root) :global(.m-width-3of4) {
        width: 75%;
    }
        :global(:root) :global(.m-width-4of6),
    :global(:root) :global(.m-width-2of3) {
        width: calc(100% / 6 * 4); /* 66.66 */
    }
        :global(:root) :global(.m-width-3of5) {
        width: 60%;
    }
        :global(:root) :global(.m-width-3of6),
    :global(:root) :global(.m-width-2of4),
    :global(:root) :global(.m-width-1of2) {
        width: 50%;
    }
        :global(:root) :global(.m-width-2of5) {
        width: 40%;
    }
        :global(:root) :global(.m-width-2of6),
    :global(:root) :global(.m-width-1of3) {
        width: calc(100% / 3); /* 33.33% */
    }
        :global(:root) :global(.m-width-1of4) {
        width: 25%;
    }
        :global(:root) :global(.m-width-1of5) {
        width: 20%;
    }
        :global(:root) :global(.m-width-1of6) {
        width: calc(100% / 6); /* 16.66 */
    }
    }
@media (min-width: 1024px) {
        :global(:root) :global(.l-width-1of1),
    :global(:root) :global(.l-width-2of2),
    :global(:root) :global(.l-width-3of3),
    :global(:root) :global(.l-width-4of4),
    :global(:root) :global(.l-width-5of5),
    :global(:root) :global(.l-width-6of6) {
        width: 100%;
    }
        :global(:root) :global(.l-width-5of6) {
        width: calc(100% / 6 * 5); /* 83.33% */
    }
        :global(:root) :global(.l-width-4of5) {
        width: 80%;
    }
        :global(:root) :global(.l-width-3of4) {
        width: 75%;
    }
        :global(:root) :global(.l-width-4of6),
    :global(:root) :global(.l-width-2of3) {
        width: calc(100% / 6 * 4); /* 66.66 */
    }
        :global(:root) :global(.l-width-3of5) {
        width: 60%;
    }
        :global(:root) :global(.l-width-3of6),
    :global(:root) :global(.l-width-2of4),
    :global(:root) :global(.l-width-1of2) {
        width: 50%;
    }
        :global(:root) :global(.l-width-2of5) {
        width: 40%;
    }
        :global(:root) :global(.l-width-2of6),
    :global(:root) :global(.l-width-1of3) {
        width: calc(100% / 3); /* 33.33% */
    }
        :global(:root) :global(.l-width-1of4) {
        width: 25%;
    }
        :global(:root) :global(.l-width-1of5) {
        width: 20%;
    }
        :global(:root) :global(.l-width-1of6) {
        width: calc(100% / 6); /* 16.66 */
    }
    }
@media (min-width: 1280px) {
        :global(:root) :global(.xl-width-1of1),
    :global(:root) :global(.xl-width-2of2),
    :global(:root) :global(.xl-width-3of3),
    :global(:root) :global(.xl-width-4of4),
    :global(:root) :global(.xl-width-5of5),
    :global(:root) :global(.xl-width-6of6) {
        width: 100%;
    }
        :global(:root) :global(.xl-width-5of6) {
        width: calc(100% / 6 * 5); /* 83.33% */
    }
        :global(:root) :global(.xl-width-4of5) {
        width: 80%;
    }
        :global(:root) :global(.xl-width-3of4) {
        width: 75%;
    }
        :global(:root) :global(.xl-width-4of6),
    :global(:root) :global(.xl-width-2of3) {
        width: calc(100% / 6 * 4); /* 66.66 */
    }
        :global(:root) :global(.xl-width-3of5) {
        width: 60%;
    }
        :global(:root) :global(.xl-width-3of6),
    :global(:root) :global(.xl-width-2of4),
    :global(:root) :global(.xl-width-1of2) {
        width: 50%;
    }
        :global(:root) :global(.xl-width-2of5) {
        width: 40%;
    }
        :global(:root) :global(.xl-width-2of6),
    :global(:root) :global(.xl-width-1of3) {
        width: calc(100% / 3); /* 33.33% */
    }
        :global(:root) :global(.xl-width-1of4) {
        width: 25%;
    }
        :global(:root) :global(.xl-width-1of5) {
        width: 20%;
    }
        :global(:root) :global(.xl-width-1of6) {
        width: calc(100% / 6); /* 16.66 */
    }
    }
@media (max-width: 575px) {
        :global(:root) :global(.maxs-width-1of1),
    :global(:root) :global(.maxs-width-2of2),
    :global(:root) :global(.maxs-width-3of3),
    :global(:root) :global(.maxs-width-4of4),
    :global(:root) :global(.maxs-width-5of5),
    :global(:root) :global(.maxs-width-6of6) {
        width: 100%;
    }
        :global(:root) :global(.maxs-width-5of6) {
        width: calc(100% / 6 * 5); /* 83.33% */
    }
        :global(:root) :global(.maxs-width-4of5) {
        width: 80%;
    }
        :global(:root) :global(.maxs-width-3of4) {
        width: 75%;
    }
        :global(:root) :global(.maxs-width-4of6),
    :global(:root) :global(.maxs-width-2of3) {
        width: calc(100% / 6 * 4); /* 66.66 */
    }
        :global(:root) :global(.maxs-width-3of5) {
        width: 60%;
    }
        :global(:root) :global(.maxs-width-3of6),
    :global(:root) :global(.maxs-width-2of4),
    :global(:root) :global(.maxs-width-1of2) {
        width: 50%;
    }
        :global(:root) :global(.maxs-width-2of5) {
        width: 40%;
    }
        :global(:root) :global(.maxs-width-2of6),
    :global(:root) :global(.maxs-width-1of3) {
        width: calc(100% / 3); /* 33.33% */
    }
        :global(:root) :global(.maxs-width-1of4) {
        width: 25%;
    }
        :global(:root) :global(.maxs-width-1of5) {
        width: 20%;
    }
        :global(:root) :global(.maxs-width-1of6) {
        width: calc(100% / 6); /* 16.66 */
    }
    }
@media (max-width: 767px) {
        :global(:root) :global(.maxm-width-1of1),
    :global(:root) :global(.maxm-width-2of2),
    :global(:root) :global(.maxm-width-3of3),
    :global(:root) :global(.maxm-width-4of4),
    :global(:root) :global(.maxm-width-5of5),
    :global(:root) :global(.maxm-width-6of6) {
        width: 100%;
    }
        :global(:root) :global(.maxm-width-5of6) {
        width: calc(100% / 6 * 5); /* 83.33% */
    }
        :global(:root) :global(.maxm-width-4of5) {
        width: 80%;
    }
        :global(:root) :global(.maxm-width-3of4) {
        width: 75%;
    }
        :global(:root) :global(.maxm-width-4of6),
    :global(:root) :global(.maxm-width-2of3) {
        width: calc(100% / 6 * 4); /* 66.66 */
    }
        :global(:root) :global(.maxm-width-3of5) {
        width: 60%;
    }
        :global(:root) :global(.maxm-width-3of6),
    :global(:root) :global(.maxm-width-2of4),
    :global(:root) :global(.maxm-width-1of2) {
        width: 50%;
    }
        :global(:root) :global(.maxm-width-2of5) {
        width: 40%;
    }
        :global(:root) :global(.maxm-width-2of6),
    :global(:root) :global(.maxm-width-1of3) {
        width: calc(100% / 3); /* 33.33% */
    }
        :global(:root) :global(.maxm-width-1of4) {
        width: 25%;
    }
        :global(:root) :global(.maxm-width-1of5) {
        width: 20%;
    }
        :global(:root) :global(.maxm-width-1of6) {
        width: calc(100% / 6); /* 16.66 */
    }
    }
@media (max-width: 1023px) {
        :global(:root) :global(.maxl-width-1of1),
    :global(:root) :global(.maxl-width-2of2),
    :global(:root) :global(.maxl-width-3of3),
    :global(:root) :global(.maxl-width-4of4),
    :global(:root) :global(.maxl-width-5of5),
    :global(:root) :global(.maxl-width-6of6) {
        width: 100%;
    }
        :global(:root) :global(.maxl-width-5of6) {
        width: calc(100% / 6 * 5); /* 83.33% */
    }
        :global(:root) :global(.maxl-width-4of5) {
        width: 80%;
    }
        :global(:root) :global(.maxl-width-3of4) {
        width: 75%;
    }
        :global(:root) :global(.maxl-width-4of6),
    :global(:root) :global(.maxl-width-2of3) {
        width: calc(100% / 6 * 4); /* 66.66 */
    }
        :global(:root) :global(.maxl-width-3of5) {
        width: 60%;
    }
        :global(:root) :global(.maxl-width-3of6),
    :global(:root) :global(.maxl-width-2of4),
    :global(:root) :global(.maxl-width-1of2) {
        width: 50%;
    }
        :global(:root) :global(.maxl-width-2of5) {
        width: 40%;
    }
        :global(:root) :global(.maxl-width-2of6),
    :global(:root) :global(.maxl-width-1of3) {
        width: calc(100% / 3); /* 33.33% */
    }
        :global(:root) :global(.maxl-width-1of4) {
        width: 25%;
    }
        :global(:root) :global(.maxl-width-1of5) {
        width: 20%;
    }
        :global(:root) :global(.maxl-width-1of6) {
        width: calc(100% / 6); /* 16.66 */
    }
    }
@media (min-width: 1279px) {
        :global(:root) :global(.maxxl-width-1of1),
    :global(:root) :global(.maxxl-width-2of2),
    :global(:root) :global(.maxxl-width-3of3),
    :global(:root) :global(.maxxl-width-4of4),
    :global(:root) :global(.maxxl-width-5of5),
    :global(:root) :global(.maxxl-width-6of6) {
        width: 100%;
    }
        :global(:root) :global(.maxxl-width-5of6) {
        width: calc(100% / 6 * 5); /* 83.33% */
    }
        :global(:root) :global(.maxxl-width-4of5) {
        width: 80%;
    }
        :global(:root) :global(.maxxl-width-3of4) {
        width: 75%;
    }
        :global(:root) :global(.maxxl-width-4of6),
    :global(:root) :global(.maxxl-width-2of3) {
        width: calc(100% / 6 * 4); /* 66.66 */
    }
        :global(:root) :global(.maxxl-width-3of5) {
        width: 60%;
    }
        :global(:root) :global(.maxxl-width-3of6),
    :global(:root) :global(.maxxl-width-2of4),
    :global(:root) :global(.maxxl-width-1of2) {
        width: 50%;
    }
        :global(:root) :global(.maxxl-width-2of5) {
        width: 40%;
    }
        :global(:root) :global(.maxxl-width-2of6),
    :global(:root) :global(.maxxl-width-1of3) {
        width: calc(100% / 3); /* 33.33% */
    }
        :global(:root) :global(.maxxl-width-1of4) {
        width: 25%;
    }
        :global(:root) :global(.maxxl-width-1of5) {
        width: 20%;
    }
        :global(:root) :global(.maxxl-width-1of6) {
        width: calc(100% / 6); /* 16.66 */
    }
    }
@media (min-width: 576px) and (max-width: 767px) {
        :global(:root) :global(.onlys-width-1of1),
    :global(:root) :global(.onlys-width-2of2),
    :global(:root) :global(.onlys-width-3of3),
    :global(:root) :global(.onlys-width-4of4),
    :global(:root) :global(.onlys-width-5of5),
    :global(:root) :global(.onlys-width-6of6) {
        width: 100%;
    }
        :global(:root) :global(.onlys-width-5of6) {
        width: calc(100% / 6 * 5); /* 83.33% */
    }
        :global(:root) :global(.onlys-width-4of5) {
        width: 80%;
    }
        :global(:root) :global(.onlys-width-3of4) {
        width: 75%;
    }
        :global(:root) :global(.onlys-width-4of6),
    :global(:root) :global(.onlys-width-2of3) {
        width: calc(100% / 6 * 4); /* 66.66 */
    }
        :global(:root) :global(.onlys-width-3of5) {
        width: 60%;
    }
        :global(:root) :global(.onlys-width-3of6),
    :global(:root) :global(.onlys-width-2of4),
    :global(:root) :global(.onlys-width-1of2) {
        width: 50%;
    }
        :global(:root) :global(.onlys-width-2of5) {
        width: 40%;
    }
        :global(:root) :global(.onlys-width-2of6),
    :global(:root) :global(.onlys-width-1of3) {
        width: calc(100% / 3); /* 33.33% */
    }
        :global(:root) :global(.onlys-width-1of4) {
        width: 25%;
    }
        :global(:root) :global(.onlys-width-1of5) {
        width: 20%;
    }
        :global(:root) :global(.onlys-width-1of6) {
        width: calc(100% / 6); /* 16.66 */
    }
    }
@media (min-width: 768px) and (max-width: 1023px) {
        :global(:root) :global(.onlym-width-1of1),
    :global(:root) :global(.onlym-width-2of2),
    :global(:root) :global(.onlym-width-3of3),
    :global(:root) :global(.onlym-width-4of4),
    :global(:root) :global(.onlym-width-5of5),
    :global(:root) :global(.onlym-width-6of6) {
        width: 100%;
    }
        :global(:root) :global(.onlym-width-5of6) {
        width: calc(100% / 6 * 5); /* 83.33% */
    }
        :global(:root) :global(.onlym-width-4of5) {
        width: 80%;
    }
        :global(:root) :global(.onlym-width-3of4) {
        width: 75%;
    }
        :global(:root) :global(.onlym-width-4of6),
    :global(:root) :global(.onlym-width-2of3) {
        width: calc(100% / 6 * 4); /* 66.66 */
    }
        :global(:root) :global(.onlym-width-3of5) {
        width: 60%;
    }
        :global(:root) :global(.onlym-width-3of6),
    :global(:root) :global(.onlym-width-2of4),
    :global(:root) :global(.onlym-width-1of2) {
        width: 50%;
    }
        :global(:root) :global(.onlym-width-2of5) {
        width: 40%;
    }
        :global(:root) :global(.onlym-width-2of6),
    :global(:root) :global(.onlym-width-1of3) {
        width: calc(100% / 3); /* 33.33% */
    }
        :global(:root) :global(.onlym-width-1of4) {
        width: 25%;
    }
        :global(:root) :global(.onlym-width-1of5) {
        width: 20%;
    }
        :global(:root) :global(.onlym-width-1of6) {
        width: calc(100% / 6); /* 16.66 */
    }
    }
@media (min-width: 1024px) and (max-width: 1339px) {
        :global(:root) :global(.onlyl-width-1of1),
    :global(:root) :global(.onlyl-width-2of2),
    :global(:root) :global(.onlyl-width-3of3),
    :global(:root) :global(.onlyl-width-4of4),
    :global(:root) :global(.onlyl-width-5of5),
    :global(:root) :global(.onlyl-width-6of6) {
        width: 100%;
    }
        :global(:root) :global(.onlyl-width-5of6) {
        width: calc(100% / 6 * 5); /* 83.33% */
    }
        :global(:root) :global(.onlyl-width-4of5) {
        width: 80%;
    }
        :global(:root) :global(.onlyl-width-3of4) {
        width: 75%;
    }
        :global(:root) :global(.onlyl-width-4of6),
    :global(:root) :global(.onlyl-width-2of3) {
        width: calc(100% / 6 * 4); /* 66.66 */
    }
        :global(:root) :global(.onlyl-width-3of5) {
        width: 60%;
    }
        :global(:root) :global(.onlyl-width-3of6),
    :global(:root) :global(.onlyl-width-2of4),
    :global(:root) :global(.onlyl-width-1of2) {
        width: 50%;
    }
        :global(:root) :global(.onlyl-width-2of5) {
        width: 40%;
    }
        :global(:root) :global(.onlyl-width-2of6),
    :global(:root) :global(.onlyl-width-1of3) {
        width: calc(100% / 3); /* 33.33% */
    }
        :global(:root) :global(.onlyl-width-1of4) {
        width: 25%;
    }
        :global(:root) :global(.onlyl-width-1of5) {
        width: 20%;
    }
        :global(:root) :global(.onlyl-width-1of6) {
        width: calc(100% / 6); /* 16.66 */
    }
    }</style>

<Framework {segment}>
    <slot />
</Framework>
