<script>
    import classNames from 'classnames'
    import * as iconset from 'assets/icons/iconset.js'
    import './featureIcons.css'

    export let type
    export let size
    let className
    export { className as class }
</script>

<style global>
    :global(.Icon) {
        display: inline-block;
        max-width: 100%;
    }
    :global(.Icon-wrapper) {
        display: block;
        width: 1em;
        height: 1em;
    }
    :global(.Icon--small) :global(.Icon-wrapper) {
        font-size: 0.85em;
    }
    :global(.Icon--big) :global(.Icon-wrapper) {
        font-size: 1.5em;
    }
    :global(.Icon--large) :global(.Icon-wrapper) {
        font-size: 2em;
    }
    :global(.Icon--huge) :global(.Icon-wrapper) {
        font-size: 5em;
    }
    :global(.Icon--feature) :global(.Icon-wrapper) {
        font-size: 8em;
        width: 1.6em;
        max-width: 100%;
    }
    :global(.Icon) :global(svg) {
        stroke: currentColor;
        fill: currentColor;
        display: block;
    }</style>

{#if type && iconset[type]}
    <div
        class={classNames('Icon', className, {
            [`Icon--${type}`]: type,
            [`Icon--${size}`]: size
        })}>
        <span class="Icon-wrapper">
            {@html iconset[type].replace(/<\?xml [^?]*\?>\n/, '')}
        </span>
    </div>
{/if}
