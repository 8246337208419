<script>
    import { onMount } from 'svelte'
    import { showNavigation } from 'stores.js'
    import SiteHeader from 'components/SiteHeader.svelte'
    import SiteFooter from 'components/SiteFooter.svelte'
    import PageLoader from 'components/PageLoader'
    import Loader from 'components/Loader'
    import Modal from 'components/Modal'
    import Analytics from 'components/Analytics.svelte'

    import { stores } from '@sapper/app'
    const { session } = stores()

    export let segment
    let loading = false
    let loadingProgress = 0

    const { preloading } = stores()
    preloading.subscribe(val => {
        if (val) {
            loading = val
        } else {
            setTimeout(() => (loadingProgress = 100), 200)
        }
    })

    onMount(() => {
        if ($session.editMode) {
            initStoryblokEvents()
        }

        const instrumentationKey = $session.applicationInsightsKey
        if (instrumentationKey) {
            initAppInsights(instrumentationKey)
        }
    })

    const initStoryblokEvents = () => {
        const sb = window.storyblok
        sb.on(['published', 'change'], () => {
            location.reload(true)
        })

        sb.pingEditor(() => {
            if (storyblok.inEditor) {
                storyblok.enterEditmode()
            }
        })
    }

    function initAppInsights(instrumentationKey) {
        import('@microsoft/applicationinsights-web').then(
            ({ ApplicationInsights }) => {
                const appInsights = new ApplicationInsights({
                    config: {
                        instrumentationKey: instrumentationKey,
                        enableAutoRouteTracking: true
                    }
                })
                appInsights.loadAppInsights()
                appInsights.trackPageView()
            }
        )
    }
</script>

<style global>
    :global(.Framework) {
        position: relative;
        margin: auto;
        display: flex;
        max-width: 100%;
        width: 100vw;
    }
    :global(.Framework-pageLoader) {
        position: fixed;
        z-index: 6;
    }
    :global(.Framework-header) {
        background: var(--color-white);
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
        max-width: 100%;
        width: var(--layout-max-width);
        margin: 0 auto;
    }
    :global(.Framework-footer) {
        flex: 1 0 auto;
        overflow: hidden;
    }
    :global(.Framework-content) {
        position: relative;
        z-index: 4;
        transition: transform var(--transition);
        width: var(--layout-content-width);
        margin: 0 auto;
    }
    :global(.Framework-wrapper) {
        opacity: 0;
        transition: opacity var(--transition-slow);
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: visible;
    }
    :global(.Framework-loaderWrapper) {
        position: absolute;
        width: var(--layout-content-width);
        max-width: var(--layout-content-width);
        top: 30rem;
        left: 50%;
        transform: translate(-50%);
        height: 90vh;
        max-height: 90vh;
        display: flex;
        overflow: hidden;
    }
    :global(.Framework-loader) {
        width: var(--layout-content-width);
    }
    :global(.Framework--loaded) :global(.Framework-wrapper) {
        opacity: 1;
        width: 100vw;
    }
    @media (max-width: 767px) {
        :global(.Framework--showNavigation) :global(.Framework-content) {
            transform: translateX(100vw);
        }

        :global(.Framework--showNavigation) :global(.Framework-loaderWrapper) {
            position: fixed;
            z-index: 6;
        }
    }</style>

{#if $session.editMode}
    <script
        src="//app.storyblok.com/f/storyblok-latest.js?t={$session.previewToken}"
        type="text/javascript">

    </script>
{/if}

<Modal>
    <div
        class="Framework Framework--loaded"
        class:Framework--showNavigation={$showNavigation}>
        <Analytics />

        {#if loading}
            <PageLoader
                class="Framework-pageLoader"
                progress={loadingProgress}
                onComplete={() => {
                    loading = false
                }} />
        {/if}

        <div class="Framework-loaderWrapper">
            <Loader randomize class="Framework-loader" />
        </div>

        <div class="Framework-wrapper">
            <SiteHeader class="Framework-header" {segment} />
            <main class="Framework-content">
                <slot />
            </main>
            <SiteFooter class="Framework-footer" {segment} />
        </div>

    </div>
</Modal>
