<script>
    import classNames from 'classnames'

    export let text = null
    export let size = null // small | large
    export let theme = 'turquoise'
    export let description = null
    let className
    export { className as class }
</script>

<style>
    .Pill {
        display: inline-block;
        padding: 0.5em 0.8em;
        border-radius: 1em;
        text-align: center;
    }
    .Pill--small {
        font-size: 0.625rem;
    }
    .Pill--pricing {
        font-size: 0.75rem;
        font-weight: 600;
        padding: 0.2em 0.4em;
    }</style>

<span
    title={description}
    class={classNames('Pill', className, {
        [`Pill--themed theme-${theme}`]: typeof theme === 'string',
        [`Pill--${size}`]: size
    })}>
    <slot>{text}</slot>
</span>
