<script>
    import classNames from 'classnames'
    import { afterUpdate } from 'svelte'
    //export let duration = 2000 // number
    export let progress = 0 // number
    export let running = true // boolean
    export let onComplete // function
    let className
    export { className as class }
    let prevPlayState

    const stepDelay = () => {
        progress = progress + 10
        if (progress >= 100 && typeof onComplete === 'function') {
            setTimeout(() => {
                onComplete()
            }, 500)
        }
        setTimeout(() => {
            if (progress < 100 && running) {
                stepDelay()
            }
        }, 100)
    }
    afterUpdate(props => {
        if (!prevPlayState && running) {
            stepDelay()
        }
        prevPlayState = running
    })
</script>

<style global>
    :global(.PageLoader) {
        height: 3px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    :global(.PageLoader-loader) {
        transition: width 100ms linear;
        background: var(--color-blue);
        height: 100%;
        display: block;
    }</style>

<div class={classNames('PageLoader', className)}>
    <div class="PageLoader-loader" style={`width:${progress}%`} />
</div>
