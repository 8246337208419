<script>
    import Article from 'components/Article'

    export let status
    export let error

    const dev = undefined === 'development'
</script>

<style>
    .ErrorPage {
        min-height: var(--layout-min-content-height);
    }</style>

<div class="ErrorPage theme-white">
    <Article title={status || 'Failed'} subtitle={error.message} columns={8}>
        {#if dev && error.stack}
            <pre>{error.stack}</pre>
        {/if}
    </Article>
</div>
