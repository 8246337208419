<script>
    import { setContext as baseSetContext } from 'svelte'
    import { fade } from 'svelte/transition'
    import Button from 'components/Button'

    export let key = 'custom-modal'
    export let closeButton = true
    export let closeOnEsc = true
    export let noWindow = false
    export let setContext = baseSetContext
    export let overlayBg = {
        background: 'var(--color-background-with-transparency)'
    }
    export let transitionBg = fade
    export let transitionBgProps = { duration: 250 }
    export let transitionWindow = transitionBg
    export let transitionWindowProps = transitionBgProps

    const defaultState = {
        closeButton,
        closeOnEsc,
        noWindow,
        overlayBg,
        transitionBg,
        transitionBgProps,
        transitionWindow,
        transitionWindowProps
    }
    let state = { ...defaultState }

    let Component = null
    let props = null

    let background
    let wrap

    const camelCaseToDash = str =>
        str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase()

    const toCssString = props =>
        Object.keys(props).reduce(
            (str, key) => `${str}; ${camelCaseToDash(key)}: ${props[key]}`,
            ''
        )

    $: cssBg = toCssString(state.overlayBg)
    $: currentTransitionBg = state.transitionBg
    $: currentTransitionWindow = state.transitionWindow

    const open = (NewComponent, newProps = {}, options = {}) => {
        Component = NewComponent
        props = newProps
        state = { ...defaultState, ...options }
    }

    const close = () => {
        Component = null
        props = null
    }

    const handleKeyup = ({ key }) => {
        if (state.closeOnEsc && Component && key === 'Escape') {
            event.preventDefault()
            close()
        }
    }

    const handleOuterClick = event => {
        if (event.target === background || event.target === wrap) {
            event.preventDefault()
            close()
        }
    }

    setContext(key, { open, close })
</script>

<style global>
    :global(.ModalBg) {
        position: fixed;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }

    :global(.ModalWindow-wrap) {
        position: relative;
        margin: 2rem;
        max-height: 100%;
    }

    :global(.ModalWindow) {
        position: relative;
        width: 30rem;
        max-width: 100%;
        max-height: 100%;
        margin: 2rem auto;
        color: var(--color-blue-main);
        border-radius: var(--border-radius);
        background: var(--color-white);
        box-shadow: var(--box-shadow);
    }

    :global(.ModalContent) {
        position: relative;
        padding: 2rem;
        max-height: calc(100vh - 8rem);
        overflow: auto;
    }

    :global(.ModalClose) {
        display: block;
        box-sizing: border-box;
        position: absolute;
        z-index: 1000;
        top: 1rem;
        right: 1rem;
        margin: 0;
        padding: 0;
    }</style>

<svelte:window on:keyup={handleKeyup} />

<div class="Modal">
    {#if Component}
        <div
            class="ModalBg"
            on:click={handleOuterClick}
            bind:this={background}
            transition:currentTransitionBg={state.transitionBgProps}
            style={cssBg}>
            <div class="ModalWindow-wrap" bind:this={wrap}>
                {#if state.noWindow}
                    <svelte:component this={Component} {...props} />
                {:else}
                    <div
                        class="ModalWindow"
                        transition:currentTransitionWindow={state.transitionWindowProps}>
                        {#if state.closeButton}
                            <Button
                                plain
                                class="ModalClose"
                                on:click={close}
                                icon={{ type: 'close', size: 'normal' }}
                                text={'Close'}
                                hiddenText />
                        {/if}
                        <div class="ModalContent">
                            <svelte:component this={Component} {...props} />
                        </div>
                    </div>
                {/if}
            </div>
        </div>
    {/if}
    <slot />
</div>
