<script>
    import classNames from 'classnames'
    import { onMount } from 'svelte'
    import flubber from 'flubber' // eslint-disable-line
    import { createEventDispatcher } from 'svelte'

    export let running = false
    export let animateIn = false
    export let randomize = false
    export let layers = 3
    export let index = 0
    let className
    export { className as class }

    let visible = false
    let animatedLayers = 0

    const dispatch = createEventDispatcher()
    const outer = {
        x: 0,
        y: 0,
        step: 150,
        width: 900,
        height: 900
    }
    const inner = {
        x: 150,
        y: 150,
        step: 100,
        width: 600,
        height: 600
    }

    let polygonPoints = [
        {
            name: 'pink',
            fill: '#F0A1DE',
            orginal:
                '143.942379,550.203539 663.557621,250.203539 721.057621,349.796461 201.442379,649.796461'
        },
        {
            name: 'dark-blue',
            fill: '#010357',
            orginal: '375,750 375,150 490,150 490,750'
        },
        {
            name: 'turquoise',
            fill: '#A1FCFE',
            orginal:
                '201.442379,250.203539 721.057621,550.203539 663.557621,649.796461 143.942379,349.796461'
        }
    ]

    // Only randomize inner steps, corners are ruled bu randomizePath
    const randomStep = () => Math.round(Math.random() * 4)

    function randomizePath(innerEdge = 'left', busyPoints = [], corner) {
        let points = {
            x1: innerEdge === 'left' ? outer.step : 0, // 650
            y1:
                innerEdge === 'bottom' ||
                innerEdge === 'left' ||
                innerEdge === 'top'
                    ? randomStep() * inner.step + inner.step + outer.step
                    : randomStep() * outer.step + outer.step,

            x2:
                innerEdge === 'left' ||
                innerEdge === 'top' ||
                innerEdge === 'right'
                    ? randomStep() * inner.step + inner.step + outer.step
                    : randomStep() * outer.step + outer.step,
            y2: innerEdge === 'top' ? outer.step : 0,

            x3: innerEdge === 'right' ? outer.width - outer.step : outer.width,
            y3:
                innerEdge === 'top' ||
                innerEdge === 'right' ||
                innerEdge === 'bottom'
                    ? randomStep() * inner.step + inner.step + outer.step
                    : randomStep() * outer.step + outer.step,

            x4:
                innerEdge === 'right' ||
                innerEdge === 'bottom' ||
                innerEdge === 'left'
                    ? randomStep() * inner.step + inner.step + outer.step
                    : randomStep() * outer.step + outer.step,
            y4:
                innerEdge === 'bottom'
                    ? outer.height - outer.step
                    : outer.height
        }

        // if any designated corner overrule randomizes points for related corner
        switch (corner) {
            case 'topleft':
                points.x1 =
                    innerEdge === 'left' || innerEdge === 'top' ? outer.step : 0
                points.y1 =
                    innerEdge === 'left' || innerEdge === 'top' ? outer.step : 0
                points.x2 =
                    innerEdge === 'top' || innerEdge === 'left' ? outer.step : 0
                points.y2 =
                    innerEdge === 'top' || innerEdge === 'left' ? outer.step : 0
                break

            case 'topright':
                points.x2 =
                    innerEdge === 'top' || innerEdge === 'right'
                        ? outer.width - outer.step
                        : outer.width
                points.y2 =
                    innerEdge === 'top' || innerEdge === 'right'
                        ? outer.step
                        : 0
                points.x3 =
                    innerEdge === 'top' || innerEdge === 'right'
                        ? outer.width - outer.step
                        : outer.width
                points.y3 =
                    innerEdge === 'top' || innerEdge === 'right'
                        ? outer.step
                        : 0
                break

            case 'bottomright':
                points.x3 =
                    innerEdge === 'bottom' || innerEdge === 'right'
                        ? outer.width - outer.step
                        : outer.width
                points.y3 =
                    innerEdge === 'bottom' || innerEdge === 'right'
                        ? outer.height - outer.step
                        : outer.height
                points.x4 =
                    innerEdge === 'bottom' || innerEdge === 'right'
                        ? outer.width - outer.step
                        : outer.width
                points.y4 =
                    innerEdge === 'bottom' || innerEdge === 'right'
                        ? outer.height - outer.step
                        : outer.height
                break

            case 'bottomleft':
                points.x4 =
                    innerEdge === 'bottom' || innerEdge === 'left'
                        ? outer.step
                        : 0
                points.y4 =
                    innerEdge === 'bottom' || innerEdge === 'left'
                        ? outer.height - outer.step
                        : outer.height
                points.x1 =
                    innerEdge === 'bottom' || innerEdge === 'left'
                        ? outer.step
                        : 0
                points.y1 =
                    innerEdge === 'bottom' || innerEdge === 'left'
                        ? outer.height - outer.step
                        : outer.height
                break
        }

        // This should not be possible for corners
        let doublePoints = busyPoints.filter(
            ([x, y]) =>
                (x === points.x1 && y === points.y1) ||
                (x === points.x2 && y === points.y2) ||
                (x === points.x3 && y === points.y3) ||
                (x === points.x4 && y === points.y4)
        )

        // if rendomized points are already taken, try again until all are unique
        while (
            busyPoints.filter(
                ([x, y]) =>
                    (x === points.x1 && y === points.y1) ||
                    (x === points.x2 && y === points.y2) ||
                    (x === points.x3 && y === points.y3) ||
                    (x === points.x4 && y === points.y4)
            ).length
        ) {
            busyPoints.forEach(([x, y]) => {
                const { points: newPoints } = randomizePath(innerEdge)
                if (
                    x === points.x1 &&
                    y === points.y1 &&
                    !(x === newPoints.x1 && y === newPoints.y1)
                ) {
                    points.x1 = newPoints.x1
                    points.y1 = newPoints.y1
                    doublePoints = doublePoints.filter(
                        doublePoint =>
                            !(doublePoint.x === x && doublePoint.y === y)
                    )
                }
                if (
                    x === points.x2 &&
                    y === points.y2 &&
                    !(x === newPoints.x2 && y === newPoints.y2)
                ) {
                    points.x2 = newPoints.x2
                    points.y2 = newPoints.y2
                    doublePoints = doublePoints.filter(
                        doublePoint =>
                            !(doublePoint.x === x && doublePoint.y === y)
                    )
                }
                if (
                    x === points.x3 &&
                    y === points.y3 &&
                    !(x === newPoints.x3 && y === newPoints.y3)
                ) {
                    points.x3 = newPoints.x3
                    points.y3 = newPoints.y3
                    doublePoints = doublePoints.filter(
                        doublePoint =>
                            !(doublePoint.x === x && doublePoint.y === y)
                    )
                }
                if (
                    x === points.x4 &&
                    y === points.y4 &&
                    !(x === newPoints.x4 && y === newPoints.y4)
                ) {
                    points.x4 = newPoints.x4
                    points.y4 = newPoints.y4
                    doublePoints = doublePoints.filter(
                        doublePoint =>
                            !(doublePoint.x === x && doublePoint.y === y)
                    )
                }
            })
        }
        return {
            points,
            path: `${points.x1},${points.y1} ${points.x2},${points.y2} ${points.x3},${points.y3} ${points.x4},${points.y4}`
        }
    }

    const shuffle = (idPrefix = '') => {
        let busyPoints = []
        const corners = [
            'topleft',
            'topright',
            'bottomright',
            'bottomleft',
            null,
            null,
            null,
            null
        ].sort(() => 0.5 - Math.random())

        const innerEdges = ['top', 'left', 'right', 'bottom'].sort(
            () => 0.5 - Math.random()
        )
        polygonPoints = polygonPoints.map((p, index) => {
            const { points, path } = randomizePath(
                innerEdges[index],
                busyPoints,
                corners[index]
            )
            busyPoints = [
                ...busyPoints,
                [points.x1, points.y1],
                [points.x2, points.y2],
                [points.x3, points.y3],
                [points.x4, points.y4]
            ]

            return {
                id: `${idPrefix}-${p.fill}`,
                ...p,
                points,
                interpolator: flubber.interpolate(
                    `M${p.orginal}z`,
                    `M${path}z`,
                    {
                        maxSegmentLength: false
                    }
                ),
                path
            }
        })
    }

    let interval
    if (layers < 3) {
        polygonPoints = polygonPoints.splice(index % 3, layers)
    }
    if (animateIn) {
        shuffle(1)
    }
    if (randomize) {
        shuffle()
    }
    if (running && !interval) {
        shuffle()
        interval = setInterval(shuffle, 1200)
    } else {
        clearInterval(interval)
        interval = false
    }

    onMount(() => {
        visible = true
    })

    function morphPolygon(node, { duration = 500, delay = 0, interpolator }) {
        return {
            delay,
            easing: undefined,
            duration,
            tick: t => {
                node.setAttribute('d', interpolator(t))
            }
        }
    }
</script>

<style>
    .Loader {
        margin: auto;
    }</style>

{#if visible}
    <div class={classNames('Loader', className)}>
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 900 900"
            style="enable-background:new 0 0 900 900;"
            xml:space="preserve">
            <g>
                {#each polygonPoints as polygon (polygon.id)}
                    {#if animateIn}
                        <path
                            fill={polygon.fill}
                            d="M{polygon.orginal}z"
                            in:morphPolygon={{ interpolator: polygon.interpolator }}
                            on:introend|once={ev => {
                                animatedLayers++
                                if (animatedLayers === 3) {
                                    dispatch(ev.type, ev.details)
                                }
                            }} />
                    {:else}
                        <path
                            fill={polygon.fill}
                            d="M{polygon.path || polygon.orginal}z" />
                    {/if}
                {/each}
            </g>
        </svg>
    </div>
{/if}
