<script>
    import classNames from 'classnames'
    import { createEventDispatcher } from 'svelte'
    import Icon from 'components/Icon'

    export let text = null // string | null
    export let content = null // string | null
    export let to = null // string | null
    export let href = null // string | null
    export let rel = null // string | null
    let className = null // string | null
    export let disabled = null // boolean | null
    export let block = null // boolean
    export let attributes = {} // any | null
    export let icon = null // string | {type: string} | null
    export let plain = null // boolean | null
    export let cover = null // boolean | null
    export let pill = null // string | null
    export let title = null // string | null

    export { className as class }

    const dispatch = createEventDispatcher()

    const attrs = {
        rel,
        ...(attributes || {})
    }

    const events = {
        click: event => {
            if (disabled) {
                event.preventDefault()
                return false
            }
            dispatch(event.type, event.detail)
        }
    }
</script>

<style global>
    :global(.Link) {
        color: inherit;
        display: inline-block;
    }
    :global(.Link--plain) {
        text-decoration: none;
        font: inherit;
        color: inherit;
    }
    :global(.Link--cover::after) {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }
    :global(.Link--block) {
        display: block;
    }</style>

<a
    {title}
    href={to || href}
    aria-label={title}
    class={classNames('Link', className, {
        'Link--disabled': disabled,
        'Link--plain': plain,
        'Link--cover': cover,
        'Link--block': block
    })}
    {...attrs}
    on:click={events.click}>
    {#if icon}
        <Icon class="Link-icon" type={icon.type || icon} {...icon} />
    {/if}
    <span class="Link-text">
        <slot>{text || content}</slot>
        {#if pill}
            <span class="Link--pill">{pill}</span>
        {/if}
    </span>
</a>
