<script>
    import { globalStore } from 'stores.js'
    import classNames from 'classnames'
    import Logotype from './Logotype'
    import Navigation from './Navigation'
    import Button from 'components/Button'

    let className

    export { className as class }

    export let segment

    let navigationItems = []

    let fileTypeCount = 0

    navigationItems = $globalStore.header_navigation
    fileTypeCount = $globalStore.fileTypes
</script>

<style global>
    :global(.SiteHeader-wrapper) {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 2em;

        width: 100%;
        max-width: var(--layout-content-width);
    }

    :global(.SiteHeader-logotype) {
        width: 128px;
    }

    :global(.SiteHeader-navigationWrapper) {
        flex: 1 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    :global(.SiteHeader-download) {
        margin-left: 1em;
        max-height: 2.5em;
    }

    @media (max-width: 767px) {
        :global(.SiteHeader-navigationWrapper) {
            flex-direction: row-reverse;
            justify-content: flex-start;
        }
        :global(.SiteHeader-download) {
            margin-right: 1em;
        }
        :global(.phlink) {
            display:none;
        }
    }</style>

<header class={classNames('SiteHeader', className)}>
    <div class="SiteHeader-wrapper layout-center">
        <Logotype to="/" class="SiteHeader-logotype" />


        <div class="SiteHeader-navigationWrapper">

            <Navigation
                class="SiteHeader-navigation"
                items={navigationItems.map(({ link, anchor }) => ({
                    to: link.cached_url,
                    text: anchor,
                    pill:
                        link.cached_url === 'skills/'
                            ? fileTypeCount.toString()
                            : '',
                    selected:
                        segment === link.cached_url ||
                        `${segment}/` === link.cached_url
                }))} />
            <Button
                class="SiteHeader-download"
                theme="blue-main"
                to="/download">
                Download
            </Button>
        </div>
    </div>
</header>
