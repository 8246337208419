<script>
    import classNames from 'classnames'

    let className
    export let to = '/'
    export { className as class }

    export let version = 'filestar*' // "filestar*" | "f*"
    export let theme = 'dark-blue'
</script>

<style>
    .Logotype {
    }

    .Logotype-image {
        display: block;
    }</style>

<a href={to} class="Logotype {className}" aria-label="logo">
    <img
        src="img/logo-{version.replace('*', '_star')}-{theme}.svg"
        class="Logotype-image"
        alt="Filestar*" />
</a>
