<script>
    import classNames from 'classnames'
    import Icon from 'components/Icon'
    import { createEventDispatcher } from 'svelte'
    import { beforeUpdate } from 'svelte'

    export let text // string | null
    export let disabled // boolean | null
    let className = '' // string | null
    export let attributes = {} // any | null
    export let to // string | null
    export let rounded // boolean | null
    export let theme // string | null
    export let icon // string | {type: string}
    export let iconPosition = 'before' // 'before' | 'after' | 'below' | 'above'
    export let hiddenText = null // boolean
    export let size = null // 'small' | 'large'
    export let plain = null // boolean
    export let fill = null // boolean
    export { className as class }

    const dispatch = createEventDispatcher()

    const attrs = {
        ...(attributes || {}),
        disabled
    }

    const events = {
        click: event => {
            if (disabled) {
                event.preventDefault()
                return false
            }

            // forward the event
            dispatch('click', event.detail)
        }
    }
    const handleEvent = (event, ...args) =>
        events[event.type] ? events[event.type](event, ...args) : {}

    let classList = getClassList()

    beforeUpdate(() => {
        classList = getClassList()
    })

    function getClassList() {
        return classNames(
            'Button',
            {
                'Button--fill': fill,
                'Button--rounded': rounded,
                'Button--disabled': disabled,
                'Button--hiddenText': hiddenText,
                'Button--withIcon': icon,
                'Button--plain': plain,
                [`text-size-${size}`]: size,
                [`Button--withTheme theme-${theme}`]: theme,
                [`Button--icon${iconPosition
                    .slice(0, 1)
                    .toUpperCase()}${iconPosition.slice(1)}`]:
                    iconPosition && icon
            },
            className
        )
    }
</script>

<style global>
    :global(.Button) {
        text-decoration: none;
        color: var(--color-text);
        display: inline-flex;
        background: var(--color-primary-action);
        color: inherit;
        border: none;
        font: inherit;
        padding: 0.93em 1em 0.93em;
        transition: all 0.2s ease;
        cursor: pointer;
        align-items: center;
        outline: none;
    }

    :global(.Button--plain) {
        background: none;
        color: inherit;
        padding: 0;
        opacity: 0.5;
        transition: var(--transition);
    }

    :global(.Button--fill) {
        width: 100%;
    }

    :global(.Button--rounded) {
        border-radius: 2em;
    }

    :global(.Button--hiddenText) {
        padding: 0.5em;
        line-height: 0;
    }

    :global(.Button:hover) {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    }

    :global(.Button:active) {
        transform: scale(1.1);
    }

    :global(.Button--plain:hover),
    :global(.Button--plain:focus) {
        opacity: 1;
        box-shadow: none;
    }

    :global(.Button--disabled),
    :global(.Button--disabled:active),
    :global(.Button--disabled:hover) {
        color: #a2a2a3 !important;
        background-color: #e0e0e0 !important;
        transform: scale(1);
        box-shadow: none;
    }

    :global(.Button-icon) {
        align-items: center;
    }

    :global(.Button-content) {
        flex: 1;
        margin-left: 0.5em;
        margin-right: 0.5em;
    }

    :global(.Button--iconAfter) {
        flex-direction: row-reverse;
    }

    :global(.Button--iconAbove) {
        flex-direction: column;
    }

    :global(.Button--iconBelow) {
        flex-direction: column-reverse;
    }</style>

{#if to}
    <a
        href={to}
        {...attrs}
        class={classList}
        on:click={handleEvent}
        title={hiddenText ? text : ''}>
        {#if icon}
            <Icon
                class="Button-icon"
                {...icon}
                type={icon.type || icon}
                size={icon.size || (hiddenText && 'large')} />
        {/if}
        <span
            class={classNames('Button-content', { 'hidden-text': hiddenText })}>
            <slot>{text}</slot>
        </span>
    </a>
{:else}
    <button
        {...attrs}
        class={classList}
        on:click={handleEvent}
        title={hiddenText ? text : ''}>
        {#if icon}
            <Icon
                class="Button-icon"
                {...icon}
                type={icon.type || icon}
                size={icon.size || (hiddenText && 'large')} />
        {/if}
        <span
            class={classNames('Button-content', { 'hidden-text': hiddenText })}>
            <slot>{text}</slot>
        </span>
    </button>
{/if}
