<script>
    import classNames from 'classnames'
    import ConditionalLink from 'utils/ConditionalLink'

    export let to = null
    export let rel = null
    export let html = null
    export let text = null
    export let content = null
    let className
    export { className as class }
</script>

<style>
    .ListItem {
        display: block;
        padding: 0;
        margin: 0;
    }</style>

<li class={classNames('ListItem', className)} on:click>
    <ConditionalLink
        if={to}
        linkProps={{ to, rel, plain: true }}
        class="ListItem-link">
        <slot>
            {#if html}
                {@html html}
            {:else}{text || content}{/if}
        </slot>
    </ConditionalLink>
</li>
