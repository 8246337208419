<script>
    import classNames from 'classnames'
    let className
    export { className as class }
</script>

<style>
    .GutterItem {
        flex: 0 0 auto;
        padding: calc(var(--gutter-gap) / 2);
    }</style>

<div class={classNames('GutterItem', className)}>
    <slot />
</div>
