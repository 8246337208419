<script>
    import { globalStore } from 'stores.js'
    import { Grid, GridCell } from 'components/Grid'
    import { Gutter, GutterItem } from 'components/Gutter'
    import classNames from 'classnames'
    import List from 'components/List'
    import Title from 'components/Title'
    import Link from 'components/Link'
    import Logotype from 'components/Logotype'
    import SocialButtons from 'components/SocialButtons'
    export let segment
    let className
    export { className as class }

    let global = $globalStore
</script>

<style global>
    :global(.SiteFooter) {
        position: relative;
        z-index: 1;
        overflow-x: hidden;
    }
    :global(.SiteFooter-content) {
        max-width: var(--layout-content-width);
    }
    :global(.SiteFooter) :global(a) {
        opacity: 0.5;
        transition: opacity var(--transition);
    }
    :global(.SiteFooter) :global(a:hover) {
        opacity: 1;
    }
    :global(.SiteFooter-copyright) {
        opacity: 0.35;
    }
    :global(.SiteFooter-logo) {
        width: 50px;
    }
    :global(.SiteFooter-logo),
    :global(a.SiteFooter-logo) {
        opacity: 1;
    }
    :global(.SiteFooter-socialButtons) {
        opacity: 0.5;
    }</style>

<footer
    class={classNames('SiteFooter theme-darker-blue layout-vertical-padding text-small', className)}>
    <div class="columns-12 layout-center">
        <div class="SiteFooter-content">
            <Grid columns={12} withGap>
                <GridCell columns={{ l: 6 }} valign="middle">
                    <Gutter vertical>
                        <GutterItem>
                            <Logotype
                                version="f*"
                                theme="white"
                                class="SiteFooter-logo" />
                        </GutterItem>
                    </Gutter>
                </GridCell>

                <GridCell columns={{ l: 3 }}>
                    <div class="text-heading5">Filestar.com</div>
                    <List
                        items={global.footer_navigation.map(item => ({
                            to: item.link.cached_url,
                            text: item.anchor,
                            rel: (
                                (item.prefetch ? 'prefetch' : '') +
                                ' ' +
                                (item.nofollow ? 'nofollow' : '')
                            ).trim(),
                            onClick: () => {
                                segment === item.link.cached_url
                                    ? window.scrollTo(0, 0)
                                    : undefined
                            }
                        }))} />
                </GridCell>

                <GridCell columns={{ l: 3 }}>
                    <div class="text-heading5">Support</div>
                    <Gutter vertical>
                        <GutterItem>
                            <Link
                                block
                                plain
                                to="mailto:{global.company_email}"
                                text={global.company_email} />
                        </GutterItem>
                        <GutterItem class="SiteFooter-socialButtons">
                            <SocialButtons />
                        </GutterItem>
                    </Gutter>
                </GridCell>

                <GridCell columns={12}>
                    <div class="SiteFooter-copyright">
                        <p>
                            Made in Sweden by {global.company_name} &bullet;
                            Copyright 2018-{new Date().getFullYear()} &bullet; {global.company_vat}
                        </p>
                    </div>
                </GridCell>
            </Grid>
        </div>
    </div>
</footer>
