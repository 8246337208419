<script>
    import marked from 'marked'
    import classNames from 'classnames'
    import PageBlock from 'components/PageBlock'
    import Title from 'components/Title'
    import { getContext } from 'svelte'
    import { blockRendererContextKey } from 'keys.js'

    var blockRendererContext = getContext(blockRendererContextKey)
    const blockIndex =
        (blockRendererContext && blockRendererContext.blockIndex()) || 0

    export let title = null
    export let subtitle = null
    export let centerTitle = true
    export let content = null
    export let columns = null
    export let contentColumns = 1;
    export let theme = null
    export let withTransparentBackground = false
    export let withoutBottomPadding = false
    export let withoutTopPadding = false

    let className
    export { className as class }
</script>

<style global>
:global(.Article-header) {
    margin-bottom: var(--layout-gutter);
}

:global(.Article-content--2col), :global(.Article-content--3col) {
    grid-column-gap: 20px;
    -moz-column-gap: 20px;
         column-gap: 20px; 
}

@media (min-width: 500px) {
    :global(.Article-content--2col) {
        -moz-column-count: 2;
             column-count: 2; 
    }
    :global(.Article-content--3col) {
        -moz-column-count: 3;
             column-count: 3; 
    }
}</style>

<PageBlock
    {columns}
    {theme}
    {withTransparentBackground}
    {withoutBottomPadding}
    {withoutTopPadding}>
    <article class={classNames('Article', className)}>
        <slot name="header">
            {#if title || subtitle}
                <header class="Article-header">
                    {#if title}
                        <Title
                            level={blockIndex == 0 ? 1 : 2}
                            asLevel={1}
                            {...title}
                            align={title.align || (centerTitle && 'center')}
                            class={classNames('Article-title', title.class)}
                            text={title.text || title} />
                    {/if}
                    {#if subtitle}
                        <Title
                            level={blockIndex ? 2 : 3}
                            asLevel={2}
                            {...subtitle}
                            align={subtitle.align || (centerTitle && 'center')}
                            class={classNames('Article-subtitle', subtitle.class)}
                            text={subtitle.text || subtitle} />
                    {/if}
                </header>
            {/if}
        </slot>
        <div class={classNames('Article-content',  { 'Article-content--2col': contentColumns == 2, 'Article-content--3col': contentColumns == 3 })}>
            <slot>
                {#if content}
                    {@html marked(content)}
                {/if}
            </slot>
        </div>
    </article>
</PageBlock>
