<script>
    import classNames from 'classnames'
    import Title from 'components/Title'
    import { getContext } from 'svelte'
    import { blockRendererContextKey } from 'keys.js'
    import marked from 'marked'

    var blockRendererContext = getContext(blockRendererContextKey)
    const blockIndex =
        (blockRendererContext && blockRendererContext.blockIndex()) || 0
    const isLastBlock =
        blockRendererContext && blockRendererContext.isLastBlock()

    export let fill = false
    export let theme = null
    export let content = null
    export let columns = null
    export let title = null
    export let subtitle = null
    export let introduction = null
    export let withTransparentBackground = false
    export let withoutBackground = false
    export let withoutBottomPadding = false
    export let withoutTopPadding = false
    export let extraPaddingIfLast = false
    export let centerTitle = true

    let className
    export { className as class }
</script>

<style global>
    :global(.PageBlock) {
        background: var(--color-background);
        padding-top: var(--block-vertical-padding);
        padding-bottom: var(--block-vertical-padding);
        position: relative;
        z-index: 0;
        max-width: 100%;
    }

    :global(.PageBlock--extraPaddingIfLast) {
        padding-bottom: calc(var(--block-vertical-padding) * 2);
    }

    :global(.PageBlock--withTransparentBackground) {
        background: var(--color-background-with-transparency);
    }

    :global(.PageBlock--withoutBottomPadding) {
        padding-bottom: 0;
    }

    :global(.PageBlock--withoutTopPadding) {
        padding-top: 0;
    }

    :global(.PageBlock--withoutBackground) {
        background: none;
    }

    :global(.PageBlock-header) {
        margin-bottom: var(--layout-gutter);
    }

    :global(.PageBlock-title-center) {
        text-align: center;
    }

    :global(.PageBlock-subtitle-center) {
        text-align: center;
    }

    :global(.PageBlock-subtitle-left) {
        text-align: left;
    }

    :global(.PageBlock-title-left) {
        text-align: left;
    }

    :global(.PageBlock-introduction) {
        text-align: center;
        font-size: 1.4em;
    }

    :global(.PageBlock) + :global(.PageBlock) {
        padding-top: 0;
    }

    :global(.PageBlock--fill) {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: var(--block-vertical-padding);
        margin-bottom: var(--block-vertical-padding);
        max-width: 100vw;
        width: 100vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    :global(.PageBlock--fill:last-child) {
        margin-bottom: 0;
    }

    :global(.PageBlock-content--vertical-padding) {
        padding-top: var(--block-vertical-padding);
        padding-bottom: var(--block-vertical-padding);
    }</style>

<section
    class={classNames('PageBlock', className, {
        'PageBlock--fill': fill,
        'PageBlock--withTransparentBackground': withTransparentBackground,
        'PageBlock--withoutBackground':
            !withTransparentBackground && withoutBackground,
        'PageBlock--extraPaddingIfLast': isLastBlock && extraPaddingIfLast,
        'PageBlock--withoutBottomPadding': withoutBottomPadding,
        'PageBlock--withoutTopPadding': withoutTopPadding,
        [`PageBlock--withTheme theme-${theme}`]: theme
    })}>
    <div
        class={classNames(
            'layout-center',
            {
                [columns && typeof columns === 'object'
                    ? Object.keys(columns)
                          .map(
                              prefix =>
                                  `${prefix ? `${prefix}-` : ''}columns-${
                                      columns[prefix]
                                  }`
                          )
                          .join(' ')
                    : `columns-${columns}`]: columns
            },
            'PageBlock-content',
            { 'PageBlock-content--vertical-padding': fill }
        )}>

        {#if title || subtitle}
            <header class="PageBlock-header">
                {#if title}
                    <Title
                        class={centerTitle ? 'PageBlock-title-center' : 'PageBlock-title-left'}
                        level={blockIndex == 0 ? 1 : 2}
                        asLevel={1}
                        text={title} />
                {/if}
                {#if subtitle}
                    <Title
                        class={centerTitle ? 'PageBlock-subtitle-center' : 'PageBlock-subtitle-left'}
                        level={blockIndex == 0 ? 2 : 3}
                        asLevel={2}
                        text={subtitle} />
                {/if}
                {#if introduction}
                   <p class="PageBlock-introduction">{@html marked(introduction)}</p>
                {/if}
            </header>
        {/if}

        <div class="PageBlock-content">
            <slot>{content}</slot>
        </div>
    </div>
</section>
