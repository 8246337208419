<script>
    import classNames from 'classnames'
    let className
    export { className as class }
    export let wideGap = null // boolean
    export let tightGap = null // boolean
    export let vertical = null // boolean
    export let align = null // 'left' | 'center' | 'right'
    export let valign = null // 'top' | 'middle' | 'bottom'
    export let spread = null // boolean
</script>

<style global>
    :global(:root) {
        --gutter-gap: var(--layout-gutter, 2rem);
        --gutter-wideGap: calc(var(--gutter-gap) * 2);
        --gutter-tightGap: calc(var(--gutter-gap) / 4);
    }
    :global(.Gutter) {
        margin: calc(2rem / 2 * -1);
        margin: calc(var(--gutter-gap) / 2 * -1);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    :global(.Gutter--wideGap) {
        --gutter-gap: var(--gutter-wideGap);
    }
    :global(.Gutter--tightGap) {
        --gutter-gap: var(--gutter-tightGap);
    }
    :global(.Gutter--vertical) {
        flex-direction: column;
    }
    :global(.Gutter:not(.Gutter--vertical).Gutter--left),
    :global(.Gutter--vertical.Gutter--top) {
        justify-content: flex-start;
    }
    :global(.Gutter:not(.Gutter--vertical).Gutter--center),
    :global(.Gutter--vertical.Gutter--middle) {
        justify-content: center;
    }
    :global(.Gutter:not(.Gutter--vertical).Gutter--right),
    :global(.Gutter--vertical.Gutter--bottom) {
        justify-content: flex-end;
    }
    :global(.Gutter--spread) {
        justify-content: space-between;
    }
    :global(.Gutter:not(.Gutter--vertical).Gutter--top),
    :global(.Gutter--vertical.Gutter--left) {
        align-items: flex-start;
    }
    :global(.Gutter:not(.Gutter--vertical).Gutter--middle),
    :global(.Gutter--vertical.Gutter--center) {
        align-items: center;
    }
    :global(.Gutter:not(.Gutter--vertical).Gutter--bottom),
    :global(.Gutter--vertical.Gutter--right) {
        align-items: flex-end;
    }
    :global(.GutterItem) {
        flex: 0 0 auto;
        padding: calc(2rem / 2);
        padding: calc(var(--gutter-gap) / 2);
    }</style>

<div
    class={classNames('Gutter', className, {
        'Gutter--widegap': wideGap,
        'Gutter--tightGap': tightGap,
        'Gutter--vertical': vertical,
        'Gutter--spread': spread,
        [`Gutter--${valign}`]: valign,
        [`Gutter--${align}`]: align
    })}>
    <slot />
</div>
