<script>
    import classNames from 'classnames'
    import ListItem from './ListItem'

    let items = []
    let className = null
    let horizontal = null
    let wideGap = null
    export { items, horizontal, className as class, wideGap }
</script>

<style global>
    :global(.List) {
        display: block;
    }
    :global(.List-list) {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    :global(.List--horizontal) :global(.List-list) {
        display: flex;
        flex-direction: row;
    }
    :global(.List--horizontal) :global(.List-item) {
        padding: 0 1em;
    }
    :global(.List--horizontal.List--wideGap) :global(.List-item) {
        padding: 0 calc(2em / 2);
        padding: 0 calc(var(--layout-gutter, 2em) / 2);
    }</style>

<div
    class={classNames('List', className, {
        'List--horizontal': horizontal,
        'List--wideGap': wideGap
    })}>
    <ul class="List-list">
        <slot>
            {#each items as item, index}
                <ListItem
                    {...item}
                    on:click={item.onClick}
                    class={classNames('List-item', item.class)} />
            {/each}
        </slot>
    </ul>
</div>
