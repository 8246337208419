<script>
    import classNames from 'classnames'
    import GridCell from './GridCell.svelte'
    export let cells // any
    export let widths // string | {'': string, s: string, m: string, l: string}
    export let columns // number | {'': number, s: number, m: number, l: number}
    export let spread // boolean
    export let align // 'left' | 'center' | 'right'
    export let valign // 'top' | 'middle' | 'bottom'
    export let withGap // boolean
    export let sameHeight // boolean
    export let reversedForSmall = false // boolean
    export let reorderForSmall = false // boolean

    let className
    export { className as class }
</script>

<style global>
    :global(:root) {
        --grid-gap: 2em;
    }
    :global(.Grid) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    :global(.Grid--sameHeight) {
        align-items: stretch;
    }
    :global(.Grid--center) {
        justify-content: center;
    }
    :global(.Grid--middle) {
        align-items: center;
    }
    :global(.GridCell) {
        width: 100%;
        flex: 0 0 auto;
        max-width: 100%;
        display: flex;
        flex-direction: column;
    }
    :global(.Grid--center) :global(.GridCell) {
        justify-content: center;
    }
    :global(.Grid--middle) :global(.GridCell) {
        align-items: center;
    }
    :global(.Grid--withGap) {
        margin: 1em -1em 1em -1em;
    }
    :global(.Grid--withGap) > :global(.GridCell) {
        padding: 1em;
    }
    @media (max-width: 767px) {
        :global(.Grid--s-reversed) {
            flex-direction: column-reverse;
        }

        :global(.Grid--s-reorder) :global(.GridCell:nth-child(1)) {
            order: 2;
        }
        
        :global(.Grid--s-reorder) :global(.GridCell:nth-child(2)) {
            order: 1;
        }

        :global(.Grid--s-reorder) :global(.GridCell:nth-child(3)) {
            order: 3;
        }

        :global(.Grid--s-reorder) :global(.GridCell:nth-child(4)) {
            order: 4;
        }

        :global(.Grid--s-reorder) :global(.GridCell:nth-child(5)) {
            order: 6;
        }
        
        :global(.Grid--s-reorder) :global(.GridCell:nth-child(6)) {
            order: 5;
        }

        :global(.Grid--s-reorder) :global(.GridCell:nth-child(7)) {
            order: 7;
        }

        :global(.Grid--s-reorder) :global(.GridCell:nth-child(8)) {
            order: 8;
        }

        :global(.Grid--s-reorder) :global(.GridCell:nth-child(9)) {
            order: 10;
        }

        :global(.Grid--s-reorder) :global(.GridCell:nth-child(10)) {
            order: 9;
        }

        :global(.Grid--s-reorder) :global(.GridCell:nth-child(11)) {
            order: 11;
        }

        :global(.Grid--s-reorder) :global(.GridCell:nth-child(12)) {
            order: 12;
        }        
    }</style>

<div
    class={classNames('Grid', className, {
        [`Grid--${valign}`]: valign,
        [`Grid--${align}`]: align,
        'Grid--spread': spread,
        'Grid--withGap': withGap,
        'Grid--sameHeight': sameHeight,
        'Grid--s-reversed': reversedForSmall,
        'Grid--s-reorder': reorderForSmall
    })}>
    <slot>
        {#each cells || [] as cell}
            <GridCell
                columns={cell.columns || columns}
                widths={cell.widths || widths}>
                {cell.content}
            </GridCell>
        {/each}
    </slot>
</div>
